import { message } from 'antd';
import querystring from 'querystring';
import * as $$ from './index';

function requestGlobalConfig(url, options = {}) {
  // 这里可以做一些全局的ajax请求配置
  const newUrl = $$.addUrlQuery(url, {});

  // 全局ajax添加头
  const newOptions = options;
  newOptions.headers = {
    Accept: 'application/json',
    'Content-Type': options?.headers?.['Content-Type'] || 'application/json',
    'x-csrf-token': $$.getCookie('csrfToken'),
    admin: 'admin',
    access_token: sessionStorage.getItem('token') || 'istest2021',
    mobile: sessionStorage.getItem('mobile') || '',
    ...options.headers,
  };
  // 为了保证ajax中的Set-Cookie可以正常工作
  newOptions.credentials = 'same-origin';

  return {
    url: newUrl,
    options: newOptions,
  };
}

function createBodyString(data, type) {

  let body;
  switch (type) {
    case 'application/x-www-form-urlencoded':
      body = typeof data === 'object' ? querystring.stringify(data) : data;
      break;
    case 'application/json':
      body = typeof data === 'string' ? data : JSON.stringify(data);
      break;
    case 'multipart/form-data;':
      body = data;
      break;
    default:
      body = typeof data === 'string' ? data : JSON.stringify(data);
      break;
  }
  return body;
}

function parseJSON(response) {
  if (!response.redirected) {
    return response.json();
  }
  return response;
}

function login() {
  sessionStorage.setItem('token', '');
  sessionStorage.setItem('mobile', '');
  window.location.href = '/login';
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
function request(url, options) {
  const globalConfig = requestGlobalConfig(url, options);
  return fetch(globalConfig.url, globalConfig.options)
    .then(checkStatus)
    .then(parseJSON)
    .then(d => {
      if (d && d.code === -2) {
        if (window.location.pathname !== '/login') {
          login();
        }
      }
      if (d.code && d.msg) {
        message.error(d.msg || '服务器错误', 5);
      }
      return d;
    })
    .catch(err => {
      let shouldToastErrorMessage = true;
      if (err.response && err.response.status === 404) {
        // 404不再弹出错误提示
        shouldToastErrorMessage = false;
      }
      if (shouldToastErrorMessage) {
        message.error(err.message || '服务器错误', 5);
      }
      return { err };
    });
}

async function GET(url, query = {}, options) {
  let urlWithQuery = $$.addUrlQuery(url, query);

  urlWithQuery = await request(urlWithQuery, {
    method: 'GET',
    ...options,
  });
  return urlWithQuery;
}

async function DELETE(url, query = {}, options) {
  let urlWithQuery = $$.addUrlQuery(url, query);

  urlWithQuery = await request(urlWithQuery, {
    method: 'DELETE',
    ...options,
  });
  return urlWithQuery;
}

async function POST(url, data = {}, options = {}) {
  const urlWithQuery = await request(url, {
    method: 'POST',
    body: createBodyString(data, options.headers && options.headers['Content-Type']),
    ...options,
  });
  return urlWithQuery;
}

async function PATCH(url, data = {}, options = {}) {
  const urlWithQuery = await request(url, {
    method: 'PATCH',
    body: createBodyString(data, options.headers && options.headers['Content-Type']),
    ...options,
  });

  return urlWithQuery;
}

async function PUT(url, data = {}, options = {}) {
  const urlWithQuery = await request(url, {
    method: 'PUT',
    body: createBodyString(data, options.headers && options.headers['Content-Type']),
    ...options,
  });

  return urlWithQuery;
}

export {
  request,
  GET,
  POST,
  DELETE,
  PATCH,
  PUT,
};
