import React from 'react';
import { Form, Input, Button, message, Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';

import { login, sendCodeMessage } from './req.js';

import './index.less';

function HomeLogin({ history }) {
  const [form] = Form.useForm();

  const fetchLogin = async data => {
    sessionStorage.setItem('mobile', data.mobile);
    const res = await login(data);

    if (res && res.success) {
      sessionStorage.setItem('mobile', data.mobile);
      sessionStorage.setItem('token', res.data);
      window.location.href = '/personInjury/list';
    }else if(res?.error){
      message.error(res.error?.message);
    }
  };

  const sendCode = async () => {
    const mobile = form.getFieldValue('mobile');
    if (!mobile || mobile.length !== 11) {
      message.error('请检查手机号');
      return;
    }
    sendCodeMessage(mobile).then((res) => {
      if (res && res.success) {
        message.success('发送成功');
        // 开始倒计时
      } else if(res?.error){
        message.error(res.error?.message);
      }
    });
  };

  return (
    <div className="login" >
      <div className='card'>
        <div>
          <img width={558} src="http://huiyuan-1312091486.cos.ap-beijing.myqcloud.com/43b74127-4205-5d26-4c82-48ef0d25ac24.png" />
        </div>
        <div className='form'>
          <div className='title'>理赔通系统</div>
          <div className='tip1'>欢迎回来</div>
          <div className='tip2'>请输入您的信息</div>
          <Form
            form={form}
            layout="vertical"
            onFinish={fetchLogin}
          >
            <Row >
              <Col span={24}>
                <Form.Item
                  name="mobile"
                  label="手机号"
                  type="text"
                  rules={[
                    {
                      required: true,
                      message: '请输入手机号！',
                    },
                  ]}
                  labelCol={{ span: 6 }}
                >
                  <Input
                    style={{ width: '100%', height: '64px' }}
                    suffix={
                      <Button
                        type="text"
                        onClick={sendCode}
                      >发送</Button>
                    }
                  />
                </Form.Item>
              </Col>

            </Row>
            <Row >
              <Col span={24}>
                <Form.Item
                  label="验证码"
                  name="code"
                  type="text"
                  rules={[
                    {
                      required: true,
                      message: '请输入验证码',
                    },
                  ]}
                  labelCol={{ span: 6 }}
                >
                  <Input
                    style={{ width: '100%', height: '64px' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className='button'>
              <Button
                style={{ width: '100%', height: '64px' }}
                type="primary"
                htmlType="submit"
              >立即登录</Button>
            </div>
          </Form>
        </div>
      </div>
    </div >
  );
}

export default withRouter(HomeLogin);
