import React, { useEffect, useState } from 'react';
import {
    Form,
    Input,
    Modal,
    message,
} from 'antd';
import { sendMessage } from '../service';


const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};

const SendModal = ({
    visible,
    hideModal,
    value
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (value && value.id) {
            form.setFieldsValue(value);
        }
    }, [value]);

    const onFinish = values => {
        const mobile = form.getFieldValue('mobile');
        if (!mobile || mobile.length !== 11) {
            message.error('请检查手机号');
            return;
        }
        setLoading(true);
        const payload = {
            ...values,
            operateType: 2,
        };
        sendMessage(payload).then(res => {
            if (res.success) {
                message.success('操作成功');
                // 校验通过
                form.resetFields();
                hideModal();
            } else if (res.error) {
                message.error(res.error?.message);
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            message.error('操作失败');
        });
    }





    return (
        <Modal
            title='资料收集'
            visible={visible}
            onCancel={() => { hideModal(); form.resetFields(); }}
            onOk={() => { form.submit(); }}
            okText="提交"
            cancelText="取消"
            width={600}
            loading={loading}
        >
            <Form
                {...formItemLayout}
                form={form}
                name="transfer"
                onFinish={onFinish}
            >
                                <Form.Item
                    name="id"
                    label="案件ID"
                >
                    <Input disabled style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    name="mobile"
                    label="手机号"
                    rules={[{ required: true, message: '请输入手机号!', }]}
                >
                    <Input style={{ width: '100%' }} />
                </Form.Item>

            </Form>
        </Modal>
    );
};

export default SendModal;
