import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Modal, Table, message } from 'antd';
import { isEmpty } from '../../../utils'
import { queryOperateRecord } from '../service';
import { OpreateTypeMap } from '../../config';

const OpreateTable = ({
  visible,
  hideModal,
  value
}) => {
  const [datasource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (value && value.id) {
      setLoading(true);
      queryOperateRecord(value.id).then(res => {
        if (res.success) {
          setDataSource(res.data);
        } else if (res.error) {
          message.error(res.error?.message)
        }
        setLoading(false);
      })
        .catch(err => {
          setLoading(false);
        });
    }
  }, [value]);

  const columns = [{
    title: '操作人姓名',
    dataIndex: 'userName',
    key: 'userName',
    width: 100,
    align: 'center',
    render: data => !isEmpty(data) ? data : '--',
  }, {
    title: '操作人账号',
    dataIndex: 'mobile',
    key: 'mobile',
    width: 100,
    align: 'center',
    render: data => !isEmpty(data) ? data : '--',
  }, {
    title: '操作时间',
    dataIndex: 'operateTime',
    key: 'operateTime',
    width: 120,
    align: 'center',
    render: data => !isEmpty(data) ? moment(data).format('YYYY-MM-DD HH:mm:ss') : '--',
  }, {
    title: '操作',
    dataIndex: 'operateType',
    key: 'operateType',
    width: 100,
    align: 'center',
    render: data => !isEmpty(data) ? OpreateTypeMap[data] : '--',
  },];

  return (
    <Modal
      title="操作记录"
      visible={visible}
      loading={loading}
      footer={null}
      onCancel={hideModal}
      width={900}
    >
      <Table
        columns={columns}
        dataSource={datasource}
        width={900}
        rowKey={record => `data-view-${record.operateTime}-${record.eventName}`}
      />
    </Modal>
  );
};

export default OpreateTable;
