const COS = require('cos-js-sdk-v5')
var cos = new COS({
  SecretId: 'AKIDKYhjbKrCD2e8UedQ1PYWM0VpvTowq10p', // 用户id
  SecretKey: 'kIcnnkmjcjptrjbRCA3rsqpjZJwRp6J0' // 用户密钥
})
 
const httpUploadRequest = (res) => {
  return new Promise((resolve, reject) => {
    const paths = res.file.name.split('.')
    const suffix = '.' + paths[paths.length - 1]
    const filename = guid() + suffix
    cos.putObject(
      {
        Bucket: 'personal-injury-1317474819', // 必须:存储桶
        Region: 'ap-beijing', // 存储桶所在地域，必须字段,
        Key: '/' + filename, // 必须 :目录/文件名
        Body: res.file, // 上传文件对象
        onProgress: function (progressData) {
          // 进度条
          console.log(JSON.stringify(progressData))
        }
      },
      (err, data) => {
        if (data.statusCode === 200) {
          resolve(data)
        } else {
          reject(err)
        }
      }
    )
  })
}
 
function guid() {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() +        S4()
}
 
export default httpUploadRequest