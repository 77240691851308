
export const SysAdminStatus = {
    1: "正常",
    2: "禁用"
}

export const SysAdminColoredStatus = {
    1: { title: "正常", color: 'green' },
    2: { title: "禁用", color: 'red' },
}

export const SysUserRoleMap = {
    1: "管理员",
    0: "普通用户"
}


export const getDefaultParams = () => ({
    name: '',
    mobile: '',
    role: '',
})

export const getDefaultPaging = () => ({
    total: 0,
    offset: 0,
    limit: 10,
})