import React from 'react';
import ReactDOM from 'react-dom';

import Router from './router';

import './index.less';


ReactDOM.render(
  <Router />,
  document.getElementById('root')
);
