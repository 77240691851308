import React, { useEffect, useState } from "react";
import {
  Popover, Table, Button, Tag
} from 'antd';
import { GET, POST } from '../utils/request';


async function fetchDownloadList() {
  const res = await POST(
    '/api/@injury/download/list',
  );
  return res;
}

async function getDownloadUrl(id) {
  const res = await GET(
    '/api/@injury/download/downloadUrl',
    {
      id: Number(id),
    }
  );
  return res;
}

const download = id => {
  getDownloadUrl(id).then(res => {
    if (res.success && res.data) {
      window.open(res.data);
    }
  });
};

// 下发渠道枚举
const statusMap = {
  1: {
    title: '导出中',
    color: 'default',
  },
  2: {
    title: '导出成功',
    color: 'green',
  },
  3: {
    title: '导出失败',
    color: 'red',
  },
};

let flag = false;
let timer = null;

export default function Download({
  insertButton,
  refresh
}) {
  const [downloadList, setDownload] = useState([]);

  const fetch = () => {
    fetchDownloadList().then(res => {
      if (res && res.success && Array.isArray(res.data)) {
        setDownload(res.data);

        flag = res.data.every(item => [2, 3].includes(item.status));

        if (flag) {
          clearInterval(timer);
        }
      } else {
        setDownload([]);
      }
    });
  }

  useEffect(() => {
    if (!refresh) return;
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
    timer = setInterval(() => {
      fetch();
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, [refresh]);

  useEffect(() => {
    timer = setInterval(() => {
      fetch();
    }, 1500);
  }, []);


  const columns = [{
    title: '操作人',
    dataIndex: 'operator',
    key: 'operator',
    align: 'center',
  }, {
    title: '导出时间',
    dataIndex: 'operateTime',
    key: 'operateTime',
    align: 'center',
  }, {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: data => <Tag color={statusMap[Number(data)]?.color}>{statusMap[Number(data)]?.title}</Tag>,
  }, {
    title: '下载地址',
    dataIndex: 'url',
    key: 'download-url',
    align: 'center',
    render: (data, record) => record.status === 2 ? <Button type="link" onClick={() => { download(record.id); }} >立即下载</Button> : '',
  }];

  const getContent = () => {
    return (downloadList && downloadList.length) ? <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
      <Table
        columns={columns}
        dataSource={downloadList}
        showHeader={null}
        footer={null}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
    </div> : '';
  };

  return ((downloadList.length) ? <Popover content={getContent}>
    {insertButton}
  </Popover> : insertButton);
}
