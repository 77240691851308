import React, { useLayoutEffect, useState, useImperativeHandle, forwardRef } from 'react';
import {
    Input,
    InputNumber,
    Tooltip,
    Form,
    Row,
    Col,
    Table
} from 'antd';
import _ from 'lodash'
import { isEmpty } from '../../../utils'
import { responsibilityRatioMap } from '../config'
import { generateId } from '../../../utils'
import { add } from 'mathjs'

const StandardForm = ({
    lossOrderInfoModel = {},
    enumInfo = {},
    formDisabled = false,
    responsibilityRatio
}, ref) => {

    const [form] = Form.useForm();
    const [id, setId] = useState(null) // 定损单id
    const [compensationItemMap, setCompensationItemMap] = useState({}) // 赔偿项目枚举
    const [dataSource, setDataSource] = useState([]);

    useImperativeHandle(ref, () => ({
        myForm: form,
        getData: getData,
        validate: validate
    }));

    const getData = () => {
        const submitInfo = form.getFieldsValue()
        return {
            ...lossOrderInfoModel,
            ...submitInfo,
            id,
            lossOrderResultList: dataSource
        }
    }

    const validate = async () => {
        const keys = ['bodyName', 'diagnoseName', 'treatmentWayName', 'icd10Code', 'icd10', 'disabled']
        const valid = dataSource.every(data => {
            return Object.keys(data).every(key => {
                if (keys.includes(key)) {
                    return !isEmpty(data[key])
                } else {
                    return true
                }
            })
        }
        )
        if (!valid) throw new Error('定损单未填写完整')
    }

    useLayoutEffect(() => {
        if (lossOrderInfoModel?.lossOrderResultList) {
            setDataSource(lossOrderInfoModel?.lossOrderResultList?.map(item => ({
                ...item,
                rowKey: generateId()
            })));
            form.setFieldsValue(lossOrderInfoModel);
            if (lossOrderInfoModel?.id) {
                setId(lossOrderInfoModel?.id)
            }
        }
        if (enumInfo) {
            setCompensationItemMap(enumInfo.compensationItemMap)
        }
        if (responsibilityRatio) {
            const formInfo = form.getFieldsValue()
            form.setFieldsValue({ ...formInfo, responsibilityRatio: responsibilityRatioMap[+responsibilityRatio] });
        }
    }, [lossOrderInfoModel, enumInfo, responsibilityRatio]);

    const getFormula = formula => (formula || []).map((item, itemIdx) => (
        <>
            {
                (item.fieldValue !== null && item.fieldUnit) !== null ?
                    <>
                        {
                            itemIdx > 0 && <span> *</span>
                        }
                        <span>{item.fieldValue}{item.fieldUnit}</span>
                    </> : '--'
            }
        </>
    ));

    const getInputFormula = (formula, index) => (formula || []).map((item, itemIdx) => {
        return <>
            {
                (item.fieldValue || item.fieldUnit) ?
                    <>
                        {
                            itemIdx > 0 && <span> * </span>
                        }
                        <Input
                            key={itemIdx}
                            min={0}
                            disabled={formDisabled}
                            style={{ width: '70px' }}
                            value={item.fieldValue}
                            suffix={item.fieldUnit}
                            onChange={(e) => changeValue(e, index, itemIdx)}
                        />
                    </> : '--'
            }
        </>
    });

    const onFormLayoutChange = (values) => {
        if (!values) return;
        const { mediateTotalAmount = 0, compulsoryInsuranceAmount = 0, responsibilityRatio = 0 } = form.getFieldsValue()
        if (values.mediateTotalAmount) {
            calculate(values.mediateTotalAmount, compulsoryInsuranceAmount, responsibilityRatio)
        } else if (values.compulsoryInsuranceAmount) {
            calculate(mediateTotalAmount, values.compulsoryInsuranceAmount, responsibilityRatio)
        }
        else if (values.responsibilityRatio) {
            calculate(mediateTotalAmount, compulsoryInsuranceAmount, values.responsibilityRatio)
        }

    }

    const calculate = (mediateTotalAmount, compulsoryInsuranceAmount, responsibilityRatio) => {
        if (mediateTotalAmount !== null && responsibilityRatio !== null) {
            let shouldCompensationAmount = (mediateTotalAmount - Number(compulsoryInsuranceAmount)) * responsibilityRatio / 100 + Number(compulsoryInsuranceAmount)
            if (typeof shouldCompensationAmount === 'number') {
                shouldCompensationAmount = Math.round(shouldCompensationAmount * 100) / 100;
                form.setFieldsValue({ shouldCompensationAmount })
            }
        }
    }

    const updateForm = (mediateTotalAmount) => {
        if (!mediateTotalAmount) return;
        form.setFieldsValue({ mediateTotalAmount })
        // 手动触发一次form更新
        onFormLayoutChange({ mediateTotalAmount })
    }

    const changeValue = (e, index, itemIdx) => {
        const value = e.target.value;
        const updateData = _.cloneDeep(dataSource);
        if (updateData[index].mediateFormula &&
            updateData[index].mediateFormula[itemIdx]
        ) {
            updateData[index].mediateFormula[itemIdx].fieldValue = value
            // 计算赔偿金额
            let sum = 1
            updateData[index].mediateFormula.forEach(item => {
                sum = sum * Number(item.fieldValue)
                if (item.fieldUnit === '%') {
                    sum = sum / 100
                }
            })

            updateData[index].mediateAmount = sum.toFixed(2)

            // 联动needChange为true的定损项
            if (updateData[index]?.needChange) {
                updateData[index].standardAmount = sum.toFixed(2)
                updateData[index].standardFormula = updateData[index].mediateFormula
            }

        }
        setDataSource(updateData)
        console.log(value, index, itemIdx, updateData[index].mediateFormula[itemIdx].fieldValue);
    }

    const columns = [
        {
            title: '序号',
            key: 'id',
            width: 30,
            align: 'center',
            fixed: 'left',
            render: (data, record, index) => (index + 1),
        },
        {
            title: '赔偿项目',
            dataIndex: 'itemId',
            key: 'itemId',
            width: 60,
            align: 'center',
            render: (text, record, index) => !isEmpty(text) ? compensationItemMap[text] : '--',
        },
        {
            title: '国家标准',
            children: [
                {
                    title: '赔偿金额',
                    dataIndex: 'standardAmount',
                    key: 'standardAmount',
                    width: 60,
                    align: 'center',
                    render: (text, record, index) => !isEmpty(text) ? `${text}元` : '--',
                },
                {
                    title: '计算公式',
                    dataIndex: 'standardFormula',
                    key: 'standardFormula',
                    width: 120,
                    align: 'center',
                    render: (text, record, index) => !isEmpty(text) ? getFormula(text) : '--',
                },
            ]
        },
        {
            title: '调解金额',
            children: [
                {
                    title: '赔偿金额',
                    dataIndex: 'mediateAmount',
                    key: 'mediateAmount',
                    width: 60,
                    align: 'center',
                    render: (text, record, index) => !isEmpty(text) ? `${text}元` : '--',
                },
                {
                    title: '计算公式',
                    dataIndex: 'mediateFormula',
                    key: 'mediateFormula',
                    width: 160,
                    align: 'center',
                    render: (text, record, index) => getInputFormula(text, index),
                },
            ]
        },
        {
            title: '法律条文',
            dataIndex: 'legalProvision',
            key: 'legalProvision',
            width: 120,
            align: 'center',
            ellipsis: {
                showTitle: false,
            },
            render: (text, record, index) =>
                <Tooltip placement="topLeft" title={text}>
                    {!isEmpty(text) ? text : '--'}
                </Tooltip>
        }
    ];

    return (
        <>
            <div className='standard-tip'> 根据最高法发布《关于修改〈最高人民法院关于审理人身损害赔偿案件适用法律若干问题的解释〉的决定》执行，自2022年5月1日起施行。</div>
            <Table
                className='standard-table'
                scroll={{ x: 540 }}
                columns={columns}
                dataSource={dataSource}
                bordered
                rowKey={(record) => record?.rowKey}
                pagination={false}
                summary={(pageData) => {
                    let standardTotalAmount = 0;
                    let mediateTotalAmount = 0;
                    pageData.forEach(({ standardAmount, mediateAmount }) => {
                        standardTotalAmount = add(+standardTotalAmount, +standardAmount);
                        mediateTotalAmount = add(+mediateTotalAmount, +mediateAmount);
                    });
                    standardTotalAmount = standardTotalAmount.toFixed(2);
                    mediateTotalAmount = mediateTotalAmount.toFixed(2);
                    updateForm(mediateTotalAmount)
                    return (
                        <>{
                            dataSource.length ? <Table.Summary.Row className='standard-summary'>
                                <Table.Summary.Cell index={0} key={0} className='standard-summary'>总计</Table.Summary.Cell>
                                <Table.Summary.Cell index={1} key={1}></Table.Summary.Cell>
                                <Table.Summary.Cell index={2} key={2}>{standardTotalAmount}</Table.Summary.Cell>
                                <Table.Summary.Cell index={3} key={3}></Table.Summary.Cell>
                                <Table.Summary.Cell index={4} key={4}>{mediateTotalAmount}</Table.Summary.Cell>
                                <Table.Summary.Cell index={5} key={5}></Table.Summary.Cell>
                                <Table.Summary.Cell index={6} key={6}></Table.Summary.Cell>
                            </Table.Summary.Row> : ''
                        }
                        </>
                    );
                }}
            ></Table>

            <div className='standard-tip'>计算公式：（定损金额 - 交强险赔付金额）* 责任比例 + 交强险赔付金额 = 应赔付金额</div>
            <Form
                form={form}
                name="StandardForm"
                layout="vertical"
                disabled={formDisabled}
                onValuesChange={onFormLayoutChange}
            >
                <Row gutter={24}>
                    <Col span={4} >
                        <Form.Item
                            name="mediateTotalAmount"
                            label="定损金额"
                            rules={[{ required: true, message: '请输入定损金额!' }]}
                        >
                            <Input disabled placeholder="请输入" />
                        </Form.Item>
                    </Col>
                    <Col span={4} >
                        <Form.Item
                            name="compulsoryInsuranceAmount"
                            label="交强险赔付金额"
                            rules={[{ required: true, message: '请输入交强险赔付金额!' }]}
                        >
                            <InputNumber style={{ width: '100%' }} min={0} precision={2} placeholder="请输入" />
                        </Form.Item>
                    </Col>
                    <Col span={4} >
                        <Form.Item
                            name="responsibilityRatio"
                            label="责任比例"
                            rules={[{ required: true, message: '请输入责任比例!' }]}
                        >
                            <Input disabled placeholder="请输入" suffix="%" />
                        </Form.Item>
                    </Col>
                    <Col span={4} >
                        <Form.Item
                            name="shouldCompensationAmount"
                            label="应赔付金额"
                            rules={[{ required: true, message: '请输入应赔付金额!' }]}
                        >
                            <Input disabled placeholder="请输入" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default forwardRef(StandardForm);
