import React from 'react';
import {
    Cascader
} from 'antd';



const CitySelect = ({
    value = null,
    onChange,
    treeData={},
    updateCityCode = () => { }
}) => {

    const handleChange = (val) => {
        onChange(val)
        updateCityCode(val)
    }

    return (
        <Cascader
            value={value}
            options={treeData}
            placeholder="请选择"
            onChange={handleChange}
        />
    );
};

export default React.memo(CitySelect);
