import React, { useEffect, useState } from 'react';
import {
    Cascader
} from 'antd';
import { queryCountry } from '../service'



const CitySelect = ({
    value = null,    
    treeData=[],
    updateCityCode = () => { }
}) => {
    const [options, setOptions] = useState([])
    useEffect(() => {
        if (treeData?.length) {
            getArea(treeData)
        }
    }, [treeData]);

    const getArea = async (list) => {
        // let list = formatOptions(treeData) 
        if (value?.[0] && value?.[1]) {
            const countryList = await getCountry(value[1])
            list = list.map(item => ({
                ...item,
                children: item.children?.map(child => {
                    return {
                        ...child,
                        isLeaf: false,
                        children: child.value === value?.[1] ? countryList : null
                    }
                })
            }))
            setOptions(list)
        } else {
            setOptions(list)
        }
    }

    const getCountry = async (city) => {
        const res = await queryCountry(city)
        return res.data ? res.data.map(child => ({
            value: child.code,
            label: child.name,
        })) : null
    }


    const loadData = async (selectedOptions) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        if (targetOption?.children) return;
        targetOption.loading = true;
        targetOption.children = await getCountry(targetOption?.value)
        setOptions([...options]);
    };

    const handleChange = (val) => {
        updateCityCode(val)
    }

    return (
        <Cascader
            value={value}
            options={options}
            loadData={loadData}
            placeholder="请选择"
            onChange={handleChange}
        />
    );
};

export default CitySelect;
