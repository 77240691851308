import React, { useLayoutEffect, useState, useImperativeHandle, forwardRef } from 'react';
import {
    Form,
    Input,
    InputNumber,
    Select,
    Row,
    Col,
    DatePicker
} from 'antd';
import moment from 'moment';
import CitySelect from './CitySelect';
import CountrySelect from './CountrySelect'
import { getDefaultBaseInfo, formatLeafTreeData, formatTreeData } from '../config'

const { TextArea } = Input;

const getOptions = optionsMap => Object.keys(optionsMap || {})?.map(key => (
    <Select.Option
        key={`${optionsMap[key]}-${key}`}
        value={key}
    >
        {optionsMap[key]}
    </Select.Option>
));

const BaseForm = ({
    basicInfo = {},
    enumInfo = {},
    treeData = {},
    formDisabled = false,
    updateResponsibilityRatio = () => { },
    updateLossTypeCode = () => { },
    updateCalculateOrder = () => { }
}, ref) => {
    const [form] = Form.useForm();
    const [sexMap, setSexMap] = useState({}) // 性别枚举
    const [claimItemMap, setClaimItemMap] = useState({}) // 赔付险种
    const [claimTreatmentTypeMap, setClaimTreatmentTypeMap] = useState({}) // 就诊类型
    const [lossTypeMap, setLossTypeMap] = useState({}) // 损失类型
    const [injureRelationMap, setInjureRelationMap] = useState({}); // 伤者关系
    const [injureTypeMap, setInjureTypeMap] = useState({}); // 人员类型
    const [dutyTypeMap, setDutyTypeMap] = useState({}); // 保司责任
    const [showDeadRatio, setShowDeadRatio] = useState(false); // 是否展示死亡系数


    useImperativeHandle(ref, () => ({
        myForm: form,
        getData: getData
    }));

    const getData = () => {
        const submitInfo = form.getFieldsValue()
        return {
            ...basicInfo,
            ...submitInfo,
            provinceCode: submitInfo?.areaCode?.[0],
            cityCode: submitInfo?.areaCode?.[1],
            locationProvinceCode: submitInfo?.CountryCode?.[0],
            locationCityCode: submitInfo?.CountryCode?.[1],
            locationAreaCode: submitInfo?.CountryCode?.[2]
        }
    }

    useLayoutEffect(() => {
        form.setFieldsValue(getDefaultBaseInfo())
        if (basicInfo && Object.keys(basicInfo).length) {
            form.setFieldsValue({
                ...basicInfo,
                damageDate: basicInfo.damageDate ? moment(basicInfo.damageDate) : '',
                areaCode: basicInfo.provinceCode ? [+basicInfo.provinceCode, +basicInfo.cityCode] : null,
                CountryCode: basicInfo.locationProvinceCode ? [+basicInfo.locationProvinceCode, +basicInfo.locationCityCode, +basicInfo.locationAreaCode] : null,
            });
            if (basicInfo.lossTypeCode) {
                setShowDeadRatio(basicInfo.lossTypeCode == 3);
            }
        }
        if (enumInfo) {
            setSexMap(enumInfo.sexMap)
            setClaimItemMap(enumInfo.claimItemMap);
            setClaimTreatmentTypeMap(enumInfo.claimTreatmentTypeMap)
            setLossTypeMap(enumInfo.lossTypeMap)
            setInjureRelationMap(enumInfo.injureRelationMap)
            setInjureTypeMap(enumInfo.injureTypeMap);
            setDutyTypeMap(enumInfo.dutyTypeMap)
        }
    }, [basicInfo, enumInfo]);

    // 表单更新
    const onFormLayoutChange = (values) => {
        if (!values) return;
        if (values.lossTypeCode) {
            setShowDeadRatio(+values.lossTypeCode === 3);
            updateLossTypeCode(+values.lossTypeCode === 2)
            // 更新定损单
            updateCalculateOrder();
        } else if (values.responsibilityRatioType) {
            updateResponsibilityRatio(values.responsibilityRatioType)
        } else if (values.claimTreatmentTypeCode) {
            // 更新定损单
            updateCalculateOrder();
        } else if (values.injureAge) {
            // 更新定损单
            updateCalculateOrder();
        }
    }

    return (
        <Form
            form={form}
            name="baseForm"
            disabled={formDisabled}
            onValuesChange={onFormLayoutChange}
        >
            <Row gutter={24}>
                <Col span={6} >
                    <Form.Item
                        name="injureName"
                        label="伤者姓名"
                        rules={[{ required: true, message: '请输入伤者姓名!' }]}
                    >
                        <Input placeholder="请输入" />
                    </Form.Item>
                </Col>
                <Col span={6} >
                    <Form.Item
                        name="injureSexCode"
                        label="伤者性别"
                        rules={[{ required: true, message: '请选择伤者性别!' }]}
                    >
                        <Select placeholder="请选择" >
                            {getOptions(sexMap)}
                        </Select>

                    </Form.Item>
                </Col>
                <Col span={6} >
                    <Form.Item
                        name="injureAge"
                        label="伤者年龄"
                        rules={[{ required: true, message: '请输入伤者年龄!' }, { type: 'number', min: 0, message: '请输入大于0的数字' }]}
                    >
                        <InputNumber placeholder="请输入" min={0} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={6} >
                    <Form.Item
                        name="injurePhone"
                        label="伤者电话"
                        rules={[{ required: true, message: '请输入伤者电话!' }, { max: 11, message: '伤者电话不得超过11位！' }]}
                    >
                        <Input placeholder="请输入" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={6} >
                    <Form.Item
                        name="trade"
                        label="从事行业"
                        rules={[{ required: true, message: '请输入从事行业!' }]}
                    >
                        <Input placeholder="请输入" />
                    </Form.Item>
                </Col>
                <Col span={6} >
                    <Form.Item
                        name="monthIncome"
                        label="月收入"
                        rules={[{ required: true, message: '请输入月收入!' }, { type: 'number', min: 0, message: '请输入大于0的数字' }]}
                    >
                        <InputNumber placeholder="请输入" min={0} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name="injureTypeCode"
                        label="人员类型"
                        rules={[{ required: true, message: '请选择人员类型!' }]}
                    >
                        <Select placeholder="请选择" >
                            {getOptions(injureTypeMap)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name="claimItemCode"
                        label="赔付险种"
                        rules={[{ required: true, message: '请选择赔付险种!' }]}
                    >
                        <Select placeholder="请选择" >
                            {getOptions(claimItemMap)}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={6} >
                    <Form.Item
                        name="claimTreatmentTypeCode"
                        label="就诊类型"
                        rules={[{ required: true, message: '请选择就诊类型!' }]}
                    >
                        <Select placeholder="请选择" >
                            {getOptions(claimTreatmentTypeMap)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6} >
                    <Form.Item
                        name="lossTypeCode"
                        label="损失类型"
                        rules={[{ required: true, message: '请选择损失类型!' }]}
                    >
                        <Select placeholder="请选择" >
                            {getOptions(lossTypeMap)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6} >
                    <Form.Item
                        name="acceptorName"
                        label="理赔员姓名"
                        rules={[{ required: true, message: '请输入理赔员姓名!' }]}
                    >
                        <Input placeholder="请输入" />
                    </Form.Item>
                </Col>
                <Col span={6} >
                    <Form.Item
                        name="acceptorPhone"
                        label="理赔员电话"
                        rules={[{ required: true, message: '请输入理赔员电话!' }, { max: 11, message: '伤者电话不得超过11位！' }]}
                    >
                        <Input placeholder="请输入" />
                    </Form.Item>
                </Col>

                <Col span={6} >
                    <Form.Item
                        name="injureRelation"
                        label="与伤者关系"
                        rules={[{ required: true, message: '请选择与伤者关系!' }]}
                    >
                        <Select placeholder="请选择" >
                            {getOptions(injureRelationMap)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6} >
                    <Form.Item
                        name="injureRelationPhone"
                        label="联系人电话"
                        rules={[{ required: true, message: '请输入联系人电话!' }, { max: 11, message: '联系人电话不得超过11位！' }]}
                    >
                        <Input placeholder="请输入" />
                    </Form.Item>
                </Col>
                {
                    showDeadRatio && <Col span={6} >
                        <Form.Item
                            name="deadRatio"
                            label="死亡系数"
                            rules={[{ required: true, message: '请输入死亡系数!' }, { type: 'number', min: 0, max: 100, message: '请输入大于0,小于100的数字' }]}
                        >
                            <InputNumber placeholder="请输入" suffix='%' style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                }
                <Col span={6} >
                    <Form.Item
                        name="areaCode"
                        label="赔偿地"
                        rules={[{ required: true, message: '请选择赔偿地!' }]}
                    >
                        <CitySelect treeData={formatLeafTreeData(treeData)} />
                    </Form.Item>
                </Col>
                <Col span={6} >
                    <Form.Item
                        name="CountryCode"
                        label="伤者所在地"
                        rules={[{ required: true, message: '请选择伤者所在地!' }]}
                    >
                        <CountrySelect treeData={formatTreeData(treeData)} updateCityCode={(value) => { form.setFieldsValue({ CountryCode: value }) }} />
                    </Form.Item>
                </Col>
                <Col span={6} >
                    <Form.Item
                        name="address"
                        label="详细地址"
                        rules={[{ required: true, message: '请选择详细地址!' }]}
                    >
                        <Input placeholder="请输入" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name="commercialInsuranceCoverage"
                        label="商业保险额"
                        rules={[{ required: true, message: '请输入商业保险额!' }, { type: 'number', min: 0, message: '请输入大于0的数字' }]}
                    >
                        <InputNumber placeholder="请输入" style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name="responsibilityRatioType"
                        label="保司责任"
                        rules={[{ required: true, message: '请选择保司责任!' }]}
                    >
                        <Select placeholder="请选择" >
                            {getOptions(dutyTypeMap)}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item
                        name="damageDate"
                        label="出险时间"
                        rules={[{ required: true, message: '请输入出险时间!' }]}
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format={'YYYY/MM/DD'}
                            placeholder={'出险时间'}

                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                name="desc"
                rules={[{ required: true, message: '请输入出险经过描述!' }]}
            >
                <TextArea rows={4} maxLength={100} placeholder="请输入出险经过描述" />
            </Form.Item>
        </Form>
    );
};

export default forwardRef(BaseForm);
