import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Col,
  Row,
  Button,
  Spin,
} from 'antd';

import Download from './download';

import './search.less';

function Search({
  formName = 'SearchForm',
  onSearch,
  SEARCH_FIELDS,
  loading,
  onExport,
  showExport,
  initialValues = {},
  otherButton,
  onResetSearch = () => { },
}) {
  const [form] = Form.useForm();
  const [refreshExportList, setRefreshExportList] = useState(false)

  const search = values => {
    // 将查询结果传出去
    onSearch(values);
  };

  const onFinishFailed = errorInfo => {
  };

  const onReset = () => {
    form.resetFields();
    onResetSearch();
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const getChildren = (FIELDS, isDrawer) => {
    const resChildren = [];
    FIELDS.map((item, index) => {
      const Ctrl = item.children;
      const otherCfg = item.otherCfg || {};
      const options = item.options || {};
      const formItemOptions = options.formItemOptions || {};
      const childrenOptions = options.childrenOptions || {};
      formItemOptions.wrapperCol = formItemOptions.wrapperCol
        || {
        xs: { span: 24 },
        sm: { span: isDrawer ? 12 : 16 }
      };
      resChildren.push(
        <Col span={options.span || (isDrawer ? 12 : 6)} key={item.key || `FIELDS-${index}`}>
          <Form.Item
            name={item.key}
            colon
            labelAlign="right"
            label={item.name}
            {...formItemOptions}
          >
            <Ctrl
              {...childrenOptions}
              size={childrenOptions.size ? childrenOptions.size : (isDrawer ? 'small' : 'default')}
            >
              {otherCfg.children}
            </Ctrl>
          </Form.Item>
        </Col>,
      );
      return '';
    });

    return resChildren;
  };

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        {...formItemLayout}
        className="search-form"
        name={formName}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={initialValues}
        onFinish={search}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="search-row">
          <Row gutter={24}>
            {
              getChildren(SEARCH_FIELDS)
            }
            <Col offset={1} span={2}>
              <Button type="primary" className="search-submit" htmlType="submit">
                查询
              </Button>
            </Col>
            <Col span={2}>
              <Button type="reset" className="search-submit" onClick={onReset}>
                重置
              </Button>
            </Col>
            {showExport ? <Col span={2}>
              <Download
                insertButton={<Button type="reset" className="search-submit" onClick={() => { onExport(); setRefreshExportList(!refreshExportList); }}>
                  导出
                </Button>}
                refresh={refreshExportList}
              />
            </Col> : ''}
            {
              otherButton ? otherButton : ''
            }
          </Row>
        </div>
      </Form>
    </Spin>
  );
}

Search.propTypes = {
  onSearch: PropTypes.func.isRequired,
  SEARCH_FIELDS: PropTypes.array.isRequired,
  OTHER_FIELDS: PropTypes.array,
  loading: PropTypes.bool,
};

Search.defaultProps = {
  OTHER_FIELDS: [],
  loading: false,
};

export default Search;
