/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
    Card, Table, Select, Modal, message, Input, Button, Upload, Tooltip
} from 'antd';
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import Search from '../../../components/search';
import CreateModal from './OpreateTable';
import { isEmpty } from '../../../utils'
import { getInjuryList, queryInjuryEnums, receive, uploadInjury, exportInjuryList, reOpenInjury, deleteInjury } from '../service';
import {
    getDefaultParams,
    getDefaultPaging
} from '../config';
import moment from 'moment';
import '../index.less'

const getOptions = optionsMap => Object.keys(optionsMap || {}).map(key => (
    <Select.Option
        key={`${optionsMap[key]}-${key}`}
        value={key}
    >
        {optionsMap[key]}
    </Select.Option>
));

// 

const HomePage = ({ user = {} }) => {

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [modalValue, setModalValue] = useState({});
    const [dataSource, setDataSource] = useState([]);
    const [paging, setPaging] = useState(getDefaultPaging());
    const [searchLoading, setSearchLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const [searchPayload, setSearchPayload] = useState(getDefaultParams());
    const [enumInfo, setEnumInfo] = useState({}); // 枚举信息



    useEffect(() => {
        getEnumInfo();
        submitSearch(searchPayload);
    }, []);

    const getEnumInfo = async () => {
        const res = await queryInjuryEnums();
        setEnumInfo(res.data || {})
        console.log(res.data, enumInfo);
    }

    const submitSearch = (payload) => {
        let searchParams = { ...payload }
        if (!payload.limit) {
            searchParams = {
                ...searchParams,
                limit: 10,
                offset: 0,
            };
            setPaging({
                limit: 10,
                offset: 0,
            });
        }
        setSearchPayload(searchParams);

        setSearchLoading(true);
        getInjuryList(searchParams).then(res => {
            if (res.success && res.data) {
                setDataSource(res.data);
                setPaging({
                    total: res.paging?.total,
                    offset: searchParams.offset,
                    limit: searchParams.limit,
                });
            } else if (res.error) {
                message.error(res.error?.message)
            }
            setSearchLoading(false);
        }).catch(err => {
            setSearchLoading(false);
        });
    };

    const pagingChange = obj => {
        submitSearch({
            ...searchPayload,
            limit: obj.limit,
            offset: obj.offset,
        });
    };

    const exportList = () => {
        setExportLoading(true);
        const searchParams = {
            ...searchPayload,
            offset: 0,
            limit: 1000000,
        };

        delete searchParams.total;
        exportInjuryList(searchParams).then(res => {
            if (res.success) {
                message.success('导出任务发起成功');
            } else if (res.error) {
                message.error(res.error?.message);
            }
            setExportLoading(false);
        }).catch(err => {
            setExportLoading(false);
        });
    }

    const hideCreateModal = () => {
        setShowCreateModal(false);
        setModalValue({});
    };

    const uploadChange = (res) => {
        console.log(res.data, res.file.response);
        if (res.file?.response?.data) {

            uploadInjury({ fileId: res.file.response.data?.[0]?.fileId }).then(uploadRes => {
                if (uploadRes.success) {

                    let content = uploadRes.data.length ? uploadRes.data.map(item => (
                        <div> 案件号: {item.reportNo}  结果: {item.result} </div>
                    )) : '上传成功'

                    Modal.info({
                        icon: <ExclamationCircleOutlined />,
                        title: '案件导入结果',
                        content,
                        okText: "确认",
                        width: '40vw',
                        onOk: () => {
                            submitSearch(searchPayload);
                        }
                    })
                } else if (uploadRes.error) {
                    message.error(uploadRes.error?.message || '导入失败')
                }
            })
        }
    }

    const handleReOpen = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: `确认重新打开已办结的案件【${record.filingNo}】吗? `,
            okText: "确认",
            cancelText: "取消",
            width: "40vw",
            onOk: () => {
                reOpenInjury({ id: record.id }).then((res) => {
                    if (res.success) {
                        message.success(`重新打开成功!!!`);
                        submitSearch(searchPayload);
                    } else if (res.error) {
                        message.error(res.error?.message);
                    }
                }).catch((err) => {
                    message.error(err?.message);
                })
            }
        })
    }

    const handleDelete = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: `确认删除案件【${record.filingNo}】吗? `,
            okText: "确认",
            cancelText: "取消",
            width: "40vw",
            onOk: () => {
                deleteInjury({ id: record.id }).then((res) => {
                    if (res.success) {
                        message.success(`删除成功!!!`);
                        submitSearch(searchPayload);
                    } else if (res.error) {
                        message.error(res.error?.message);
                    }
                }).catch((err) => {
                    message.error(err?.message);
                })
            }
        })
    }

    const handleReceive = (record) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: `确认认领案件【${record.filingNo}】吗? `,
            okText: "确认",
            cancelText: "取消",
            width: "40vw",
            onOk: () => {
                receive({ id: record.id }).then((res) => {
                    if (res.success) {
                        message.success(`认领成功!!!`);
                        submitSearch(searchPayload);
                    } else if (res.error) {
                        message.error(res.error?.message);
                    }
                }).catch((err) => {
                    message.error(err?.message);
                })
            }
        })

    }

    const columns = [
        {
            title: '赔付项目',
            dataIndex: 'claimItemCode',
            key: 'claimItemCode',
            width: 60,
            align: 'center',
            render: data => !isEmpty(data) ? enumInfo?.claimItemMap?.[data] : '--',
        },
        {
            title: '就诊类型',
            dataIndex: 'claimTreatmentTypeCode',
            key: 'claimTreatmentTypeCode',
            width: 60,
            align: 'center',
            render: data => !isEmpty(data) ? enumInfo?.claimTreatmentTypeMap?.[data] : '--',
        },
        {
            title: '损失类型',
            dataIndex: 'lossTypeCode',
            key: 'lossTypeCode',
            width: 60,
            align: 'center',
            render: data => !isEmpty(data) ? enumInfo?.lossTypeMap?.[data] : '--',
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            key: 'customerName',
            width: 80,
            align: 'center',
            render: data => !isEmpty(data) ? data : '--',
        },
        {
            title: '出险日期',
            dataIndex: 'damageDate',
            key: 'damageDate',
            width: 60,
            align: 'center',
            render: data => !isEmpty(data) ? data : '--',
        },
        {
            title: '处理人手机号',
            dataIndex: 'dealPersonMobile',
            key: 'dealPersonMobile',
            width: 80,
            align: 'center',
            render: data => !isEmpty(data) ? data : '--',
        },
        {
            title: '处理人名称',
            dataIndex: 'dealPersonName',
            key: 'dealPersonName',
            width: 80,
            align: 'center',
            render: data => !isEmpty(data) ? data : '--',
        },
        {
            title: '立案号',
            dataIndex: 'filingNo',
            key: 'filingNo',
            width: 80,
            align: 'center',
            render: data => !isEmpty(data) ? data : '--',
        },
        {
            title: '报案号',
            dataIndex: 'reportNo',
            key: 'reportNo',
            width: 80,
            align: 'center',
            render: data => !isEmpty(data) ? data : '--',
        },
        {
            title: '案件状态',
            dataIndex: 'status',
            key: 'status',
            width: 80,
            align: 'center',
            render: data => !isEmpty(data) ? enumInfo?.caseStatusMap?.[data] : '--',
        },

        {
            title: '更新时间',
            dataIndex: 'modifyTime',
            key: 'modifyTime',
            width: 80,
            align: 'center',
            render: data => !isEmpty(data) ? data : '--',
        },
        {
            title: '操作',
            key: 'operate',
            width: 120,
            align: 'center',
            fixed: 'right',
            render: record => <div>
                {+record.status === 0 &&
                    <Button type="link" onClick={() => handleReceive(record)}>认领</Button>
                }
                {(+record.status === 1 && user.mobile === record.dealPersonMobile) &&
                    <Button type="link" href={`./detail?action=edit&id=${+record.id}`}>编辑</Button>
                }
                {
                    (+record.status === 2 && user.mobile === record.dealPersonMobile) &&
                    <Button type="link" onClick={() => handleReOpen(record)}>重新打开</Button>
                }
                {
                    +record.status !== 2 &&
                    <Button type="link" onClick={() => handleDelete(record)}>删除</Button>
                }

                <Button type="link" href={`./detail?action=detail&id=${+record.id}`}>查看</Button>
                <Button type="link" href={`./media?caseId=${+record.id}`}>影像信息</Button>
                <Button type="link" href={`./agreement?caseId=${+record.id}`}>协议书</Button>
                <Button type="link" onClick={() => { setModalValue(record); setShowCreateModal(true); }}>操作记录</Button>
            </div>
        },
    ];


    const SEARCH_FIELDS = [

        {
            key: 'claimItemCode',
            name: '赔付项目',
            children: Select,
            otherCfg: {
                children: getOptions(enumInfo?.claimItemMap),
            },
            options: {
                span: 8,
            }
        },
        {
            key: 'claimTreatmentTypeCode',
            name: '就诊类型',
            children: Select,
            otherCfg: {
                children: getOptions(enumInfo?.claimTreatmentTypeMap),
            },
            options: {
                span: 8,
            }
        },
        {
            key: 'customerName',
            name: '客户名称',
            children: Input,
            options: {
                span: 8,
            }
        },
        {
            key: 'dealPersonMobile',
            name: '处理人手机号',
            children: Input,
            options: {
                span: 8,
            }
        },
        {
            key: 'dealPersonName',
            name: '处理人名称',
            children: Input,
            options: {
                span: 8,
            }
        },
        {
            key: 'reportNo',
            name: '报案号',
            children: Input,
            options: {
                span: 8,
            }
        },
        {
            key: 'filingNo',
            name: '立案号',
            children: Input,
            options: {
                span: 8,
            }
        },
        {
            key: 'lossTypeCode',
            name: '损失类型',
            children: Select,
            otherCfg: {
                children: getOptions(enumInfo?.lossTypeMap),
            },
            options: {
                span: 8,
            }
        },
        {
            key: 'status',
            name: '案件状态',
            children: Select,
            otherCfg: {
                children: getOptions(enumInfo?.caseStatusMap),
            },
            options: {
                span: 8,
            }
        },
    ];

    return (
        <>
            <Card >
                <Search
                    formName="SearchForm"
                    onSearch={submitSearch}
                    initialValues={getDefaultParams()}
                    SEARCH_FIELDS={SEARCH_FIELDS}
                    onExport={exportList}
                    showExport
                    otherButton={

                        <Tooltip placement="right" title={
                            <Button type="link" href='https://personal-injury-1317474819.cos.ap-beijing.myqcloud.com/template/%E4%BA%BA%E4%BC%A4%E6%A1%88%E4%BB%B6%E4%B8%8A%E4%BC%A0%E6%A8%A1%E7%89%88.xlsx'>
                                下载模板
                            </Button>
                        } arrow={true}>
                            <Upload
                                action='/api/@injury/common/upload'
                                accept='*'
                                maxCount={1}
                                onChange={uploadChange}
                            >
                                <Button type='primary' icon={<UploadOutlined />}>导入案件</Button>
                            </Upload>
                        </Tooltip>
                    }
                />
            </Card>
            <Card>
                <Table
                    scroll={{ x: 1800 }}
                    columns={columns}
                    dataSource={dataSource}
                    bordered
                    loading={searchLoading}
                    rowKey={record => `injury-list-${record.id}`}
                    pagination={{
                        current: Math.ceil(paging.offset / paging.limit) + 1,
                        pageSize: paging.limit,
                        total: paging.total,
                        onChange: (page, size) => {
                            pagingChange({
                                ...paging,
                                offset: (page - 1) * paging.limit,
                                limit: size,
                            });
                        }
                    }}
                ></Table>
            </Card>
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer" >京ICP备2023009270号</a>
            </div>

            <CreateModal
                visible={showCreateModal}
                hideModal={hideCreateModal}
                value={modalValue}
            />
        </>
    )
}

export default withRouter(HomePage);

