/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Card, Table, Select, Input, Button, Col, message, Modal, Tag
} from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import Search from '../../components/search';
import UserModal from './UserModal';
import OpreateTable from './OpreateTable';
import { isEmpty } from '../../utils'
import { getUserList, disableUser } from './service';
import {
  SysAdminStatus,
  SysAdminColoredStatus,
  SysUserRoleMap,
  getDefaultParams,
  getDefaultPaging
} from './config';

import './index.less'

const getOptions = optionsMap => Object.keys(optionsMap).map(key => (
  <Select.Option
    key={`${optionsMap[key]}-${key}`}
    value={key}
  >
    {optionsMap[key]}
  </Select.Option>
));


const HomePage = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [modalValue, setModalValue] = useState({});
  const [showOperateModal, setShowOperateModal] = useState(false);
  const [operateModalValue, setOperateModalValue] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [paging, setPaging] = useState(getDefaultPaging());
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchPayload, setSearchPayload] = useState(getDefaultParams());



  useEffect(() => {
    submitSearch(searchPayload);
  }, []);

  const submitSearch = (payload) => {
    let searchParams = payload;
    if (!payload.limit) {
      searchParams = {
        ...searchParams,
        limit: 10,
        offset: 0,
      };
      setPaging({
        limit: 10,
        offset: 0,
      });
    }
    setSearchPayload(searchParams);

    setSearchLoading(true);
    getUserList(searchParams).then(res => {
      if (res.success && res.data) {
        setDataSource(res.data);
        setPaging({
          total: res.data.total,
          offset: searchParams.offset,
          limit: searchParams.limit,
        });
      }
      setSearchLoading(false);
    }).catch(err => {
      setSearchLoading(false);
    });
  };

  const pagingChange = obj => {
    submitSearch({
      ...searchPayload,
      limit: obj.limit,
      offset: obj.offset,
    });
  };

  const hideCreateModal = () => {
    setShowCreateModal(false);
    setModalValue({});
    submitSearch(searchPayload);
  };

  const hideOperateModal = () => {
    setShowOperateModal(false);
    setOperateModalValue({});
  };

  const submitDisableUser = (record) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: `确认将用户 ${record.name} 禁用吗? `,
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        disableUser(record.id).then((res) => {
          if (res.success) {
            message.success(`禁用成功!!!`);
            submitSearch(searchPayload);
          } else if (res.error) {
            message.error(res.error?.message);
          }
        }).catch((err) => {
          message.error(err?.message);
        })
      }
    })
  }

  const columns = [
    {
      title: '序号',
      key: 'id',
      width: 80,
      align: 'center',
      fixed: 'left',
      render: (data, record, index) => (index + paging.offset + 1),
    },
    {
      title: '用户名',
      dataIndex: 'name',
      key: 'name',
      width: 80,
      align: 'center',
      render: data => !isEmpty(data) ? data : '--',
    },
    {
      title: '手机号',
      dataIndex: 'mobile',
      key: 'mobile',
      width: 100,
      align: 'center',
      render: data => !isEmpty(data) ? data : '--',
    },
    {
      title: '用户角色',
      dataIndex: 'role',
      key: 'role',
      width: 100,
      align: 'center',
      render: data => !isEmpty(data) ? SysUserRoleMap[data] : '--',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      align: 'center',
      render: data => !isEmpty(data) ?
        <Tag color={SysAdminColoredStatus[Number(data)]?.color}>{SysAdminColoredStatus[Number(data)]?.title}</Tag> : '--',
    },
    {
      title: '操作',
      key: 'operate',
      width: 80,
      align: 'center',
      fixed: 'right',
      render: record => <div>
        {
          record.status === 1 && <>
            <Button type="link" onClick={() => { setModalValue(record); setShowCreateModal(true); }}>编辑</Button>
            <Button type="link" className='red' onClick={() => submitDisableUser(record)}>禁用</Button>
          </>
        }
        <Button type="link" onClick={() => { setOperateModalValue(record); setShowOperateModal(true); }}>操作记录</Button>
      </div>
    },
  ];


  const SEARCH_FIELDS = [
    {
      key: 'name',
      name: '用户名',
      children: Input,
      options: {
        span: 8,
      }
    },
    {
      key: 'mobile',
      name: '手机号',
      children: Input,
      options: {
        span: 8,
      }
    },
    {
      key: 'status',
      name: '状态',
      children: Select,
      otherCfg: {
        children: getOptions(SysAdminStatus),
      },
      options: {
        span: 8,
      }
    },
  ];

  return (
    <>
      <Card>
        <Search
          formName="SearchForm"
          onSearch={submitSearch}
          initialValues={getDefaultParams()}
          SEARCH_FIELDS={SEARCH_FIELDS}
          otherButton={
            <Col span={2}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                className='search-submit'
                onClick={() => { setModalValue({}); setShowCreateModal(true); }}
              >
                新建
              </Button>
            </Col>}
        />

      </Card>
      <Card>
        <Table
          scroll={{ x: 440 }}
          columns={columns}
          dataSource={dataSource}
          bordered
          loading={searchLoading}
          rowKey={record => `user-list-${record.id}`}
          pagination={{
            current: Math.ceil(paging.offset / paging.limit) + 1,
            pageSize: paging.limit,
            total: paging.total,
            onChange: (page, size) => {
              pagingChange({
                ...paging,
                offset: (page - 1) * paging.limit,
                limit: size,
              });
            }
          }}
        ></Table>
      </Card>
      <UserModal
        visible={showCreateModal}
        hideModal={hideCreateModal}
        value={modalValue}
      />

      <OpreateTable
        visible={showOperateModal}
        hideModal={hideOperateModal}
        value={operateModalValue}
      />
    </>
  )
}

export default withRouter(HomePage);

