import { generateId } from '../../utils'


export const responsibilityRatioMap = {
    1: 100,
    2: 70,
    3: 50,
    4: 30,
    5: 0
}

export const getDefaultBaseInfo = () => ({
    acceptorName: "", // 理赔员姓名
    acceptorPhone: "", // 理赔员电话
    areaCode: null, // 赔偿低
    cityCode: null,// 赔偿地城市编码
    claimItemCode: null,// 赔付险种编码
    claimTreatmentTypeCode: null, // 案件就诊类型编码
    deadRatio: '', // 死亡系数
    desc: "", //  出险经过描述
    id: 0, //
    injureAge: '',// 伤者年龄
    injureName: "", // 伤者姓名
    injurePhone: "", // 伤者联系电话
    injureRelation: '', // 与伤者关系
    injureRelationPhone: "", // 联系人电话
    injureSexCode: null, // 伤者性别编码
    injureTypeCode: null, // 伤者人员类型
    lossTypeCode: null, // 损失类型编码
    monthIncome: '', // 月收入
    provinceCode: null, // 赔偿地省级编码
    trade: "", // 行业
    commercialInsuranceCoverage: '', // 商业险保额
    responsibilityRatioType: '',// 保司责任
    CountryCode: null, // 伤者所在地
    locationProvinceCode: '',// 伤者所在地
    locationAreaCode: '', // 伤者所在地
    locationCityCode: '', // 伤者所在地
    address: '', // 详细地址

})

export const getDefaultPayInfo = () => ({
    areaCode: null,// 赔偿使用地
    compensateYear: '', // 赔偿年度
    householdNatureCode: null // 户籍性质
})

export const getHospitalTableInfo = () => ({
    hospitalCode: "", // 医院编码
    hospitalName: "", // 医院名称
    hospitalStay: 0, // 住院天数
    id: 0,
    inHospitalDate: "", // 住院日期
    leaveHospitalDate: "", // 出院日期v
    rowKey: generateId()
})

export const getHospitalInfo = () => ({
    days: 0, // 总住院天数
    hospitalInfoModelList: [getHospitalTableInfo()]
})

// 三期
export const getThreePahseInfo = () => ({
    careDays: 0,
    delayWorkDays: 0,
    nutritionDays: 0
})

// 护理人信息
export const getCareInfo = () => ({
    id: 0,
    industryIncome: '', // 月收入
    relationCode: null // 护理人身份
})

// 被抚养人信息
export const getDependentInfo = () => ({
    dependentAge: '',
    dependentBirthday: "",
    dependentCount: '',
    dependentName: "",
    dependentShare: '',
    dependentYearTotal: '',
    id: 0,
    victimRelationCode: ""
})

// 诊断信息
export const getDiagnosticInfo = () => ({
    bodyName: "",
    diagnoseName: "", // 受伤部位
    disabled: '', // 是否涉残
    firstDiagnose: false, // 主要诊断
    icd10Code: "", // ICD10代码
    icd10Name: "", // ICD10名称
    id: null,
    superviseInjuryCode: "",
    treatMode: "", // 治疗方式
    treatmentWayName: "", // 伤情描述
    rowKey: generateId()
})


// 伤残列表
export const getInjuryList = () => ({
    appraisalOrganCode: "",
    disLevelCode: '',
    disLevelDescribe: "",
    disLevelRatio: '',
    disabledBodyName: "",
    disabledCode: "",
    id: 0,
    superviseCode: "",
    rowKey: generateId()
})

// 伤残信息
export const getInjuryInfo = () => ({
    disabledItemModelList: [getInjuryList()],
    disabledRatio: '',
    relationRatio: ''
})

export const getDefaultFormParams = () => ({
    baseInfo: getDefaultBaseInfo(),
    hospitalInfo: getHospitalInfo(),
    threePahseInfo: getThreePahseInfo(),
    careInfo: getCareInfo(),
    diagnosticInfo: [getDiagnosticInfo()],
    injuryInfo: getInjuryInfo(),
})

export const getDefaultParams = () => ({
    claimItemCode: "",
    claimTreatmentTypeCode: "",
    customerName: "",
    damageDate: "",
    dealPersonMobile: "",
    dealPersonName: "",
    filingNo: "",
    lossTypeCode: "",
    reportNo: "",
    status: null,
})


export const defaultData = () => ({
    productType: '',
    code: '',
    generateTime: [],
    channelCode: '',
    count: 1,
    rowKey: generateId()
})


export const getDefaultPaging = () => ({
    total: 0,
    offset: 0,
    limit: 10,
})


export const formatTreeData = (list) => {
    return list?.map(item => ({
        key: item.code,
        value: item.code,
        label: item.name,
        children: item.childList ? item.childList.map(child => ({
            key: child.code,
            value: child.code,
            label: child.name,
            isLeaf: false
        })) : null
    }))
}

export const formatLeafTreeData = (list) => {
    return list?.map(item => ({
        key: item.code,
        value: item.code,
        label: item.name,
        children: item.childList ? item.childList.map(child => ({
            key: child.code,
            value: child.code,
            label: child.name,

        })) : null
    }))
}