import { Button, Space, Card, message, Modal } from 'antd';
import React, { useLayoutEffect, useState, useRef } from 'react';
import { UpOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import BaseForm from './BaseForm';
import PayForm from './PayForm';
import HospitalForm from './HospitalForm'
import DependentForm from './DependentForm'
import DiagnosticForm from './DiagnosticForm'
import ThreePhaseForm from './ThreePhaseForm'
import CareForm from './CareForm'
import InjuryForm from './InjuryForm'
import StandardForm from './StandardForm'
import TransferModal from './TransferModal';
import SendModal from './SendModal'
import { search } from '../../../utils'
import { getDefaultFormParams } from '../config'

import iconAdd from '../../../assets/ic_Add-one.png'
import '../index.less'
import { queryInjuryEnums, saveInjury, queryPersonInjuryDetail, calculateOrder, submitInjury, queryArea } from '../service';



const HomePage = ({ history }) => {

    const { action, id } = search(); // URL query参数
    const [showTransferModal, setShowTransferModal] = useState(false); // 转交弹窗
    const [transferModalValue, setTransferModalValue] = useState({});
    const [showSendModal, setShowSendModal] = useState(false); //资料收集弹窗
    const [sendModalValue, setSendModalValue] = useState({});
    const [injuryInfo, setInjuryInfo] = useState({}); // 人伤信息，用于表单初始化
    const [enumInfo, setEnumInfo] = useState({}); // 枚举信息
    const [diagnosticInfo, setDiagnosticInfo] = useState(null); // 主要诊断信息
    const [areaCode, setAreaCode] = useState([]); // 赔偿地信息
    const [responsibilityRatio, setResponsibilityRatio] = useState(null); // 保司责任
    const [treeData, setTreeData] = useState([]); //城市编码
    const [formDisabled, setFormDisabled] = useState(false); // 表单禁用
    const [isUpdateAreaCode, setIsUpdateAreaCode] = useState(false); // 是否更新总赔偿系数
    const [isFold, setIsFold] = useState(false); // 赔偿信息折叠状态
    const [showInjuryForm, setShowInjuryForm] = useState(true); // 伤残信息展示

    const BaseFormRef = useRef(null); // 表单引用
    const PayFormRef = useRef(null);
    const HospitalFormRef = useRef(null);
    const DependentFormRef = useRef(null);
    const DiagnosticFormRef = useRef(null);
    const ThreePhaseFormRef = useRef(null);
    const CareFormRef = useRef(null);
    const InjuryFormRef = useRef(null);
    const StandardFormRef = useRef(null);


    useLayoutEffect(() => {
        if (action === 'detail') {
            setFormDisabled(true)
        }
        getEnumInfo();
        getArea('')
        getDetail(id);
    }, []);

    const getDetail = async (id) => {
        const res = await queryPersonInjuryDetail(id);
        console.log('getDetail', res.data);

        // 更新责任比例
        const { responsibilityRatioType, lossTypeCode } = res.data?.basicInfo || {}
        setResponsibilityRatio(responsibilityRatioType)

        // 更新赔偿地区域代码
        const { applyProAddressCode, applyCityAddressCode } = res.data?.compensationStandardInfoModel || {}
        setAreaCode([applyProAddressCode, applyCityAddressCode])
        setInjuryInfo(res.data)

        // 损失类型为一般/死亡，隐藏伤残信息
        if (+lossTypeCode === 1 || +lossTypeCode === 3) {
            setShowInjuryForm(false)
        }
    }
    // 获取枚举信息
    const getEnumInfo = async () => {
        const res = await queryInjuryEnums();
        setEnumInfo(res.data || {})
    }
    // 获取城市信息
    const getArea = async (city) => {
        const res = await queryArea(city);
        setTreeData(res.data || [])
    }


    const getSubmitData = () => {
        const basicInfo = BaseFormRef.current?.getData();
        const compensationStandardInfoModel = PayFormRef.current?.getData();
        const hospitalModel = HospitalFormRef.current?.getData();
        const caregiverInfoModelList = CareFormRef.current?.getData();
        const dependentInfoModelList = DependentFormRef.current?.getData();
        const diagnosticInfoModelList = DiagnosticFormRef.current?.getData();
        const threeLimitInfoModel = ThreePhaseFormRef.current?.getData();
        const disabledInfoModel = InjuryFormRef.current?.getData();
        const lossOrderInfoModel = StandardFormRef.current?.getData();

        return {
            id,
            basicInfo,
            compensationStandardInfoModel,
            hospitalModel,
            caregiverInfoModelList,
            dependentInfoModelList,
            diagnosticInfoModelList,
            disabledInfoModel,
            threeLimitInfoModel,
            lossOrderInfoModel
        }
    }

    // 更新定损单信息
    const updateCalculateOrder = (needMessage) => {
        setTimeout(() => {
            const params = getSubmitData()
            const { injureAge } = params?.basicInfo || {};
            const { areaCode, compensateYear } = params?.compensationStandardInfoModel || {};
            console.log('updateCalculateOrder', injureAge, areaCode, compensateYear);
            if (!injureAge || !areaCode || !compensateYear) {
                if (needMessage) return message.error('请检查伤者年龄，赔偿适用地，赔偿年度是否填写！')
                return
            };

            delete params.lossOrderInfoModel
            calculateOrder(params).then(res => {
                if (res.success) {
                    setInjuryInfo({
                        ...params,
                        lossOrderInfoModel: {
                            ...params.lossOrderInfoModel,
                            lossOrderResultList: res.data
                        }
                    })
                    message.success('定损单已更新')
                } else if (res.error) {
                    message.error(res.error?.message)
                }
            })
        }, 200)
    }

    // 更新区域代码
    const updateAreaCode = (val) => {
        const params = getSubmitData()
        setInjuryInfo(params)
        setAreaCode(val)
    }

    // 更新诊断信息
    const updateDiagnosticInfo = (val) => {
        const params = getSubmitData()
        setInjuryInfo(params)
        setDiagnosticInfo(val)
    }

    // 更新责任比例
    const updateResponsibilityRatio = (val) => {
        console.log('updateResponsibilityRatio', val);
        const params = getSubmitData()
        setInjuryInfo(params)
        setResponsibilityRatio(val)
    }

    // 保存
    const save = () => {
        const params = getSubmitData()
        console.log('保存参数', params);
        saveInjury(params).then(res => {
            if (res.success) {
                message.success('操作成功');
            } else if (res.error) {
                message.error(res.error?.message)
            }
        })
    };

    // 办结前弹窗确认
    const preSubmit = () => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: `确认办结吗? `,
            okText: "确认",
            cancelText: "取消",
            onOk: async () => {
                submit()
            },
        });
    }

    // 办结
    const submit = async () => {
        const params = getSubmitData()
        console.log('办结参数', params);
        try {
            const { caregiverInfoModelList, dependentInfoModelList, disabledInfoModel } = params || {};
            await BaseFormRef.current?.myForm.validateFields()
            await PayFormRef.current?.myForm.validateFields()
            HospitalFormRef.current?.validate()
            caregiverInfoModelList && await CareFormRef.current?.myForm.validateFields()
            dependentInfoModelList && await DependentFormRef.current?.myForm.validateFields()
            // 诊断信息必须有一条
            await DiagnosticFormRef.current?.validate()
            if (disabledInfoModel) {
                await InjuryFormRef.current?.myForm.validateFields();
                await InjuryFormRef.current?.validate()
            }
            await StandardFormRef.current.myForm.validateFields();
            submitInjury(params).then(res => {
                if (res.success) {
                    message.success('案件办结成功')
                    setTimeout(() => {
                        history.goBack()
                    }, 1500)
                } else if (res.error) {
                    message.error(res.error?.message)
                }
            })
        }
        catch (err) {
            message.error(err.message || '请检查信息是否填写完完整')
        }
    };

    // 隐藏资料手机弹窗
    const hideSendModal = () => {
        setShowSendModal(false);
        setSendModalValue({});
    };

    // 隐藏转交弹窗
    const hideCreateModal = () => {
        setShowTransferModal(false);
        setTransferModalValue({});
    };

    const getCardTitle = (title) => {
        return <div className='card-title'>{title}</div>
    }

    const getCardExtra = (formFef) => {
        return <div className='extra' onClick={() => formFef.current.handleAdd()}>
            <Button type='link' icon={<img width={14} style={{ marginRight: '8px' }} src={iconAdd} />} disabled={formDisabled}>添加</Button>
        </div >
    }

    const getCardFoldExtra = () => {
        return <div className='extra' onClick={() => setIsFold(!isFold)}>
            <Button type='link' icon={<>
                {
                    isFold ? <UpOutlined /> :
                        <DownOutlined />
                }</>} disabled={formDisabled}>{isFold ? '展开' : '收起'}</Button>
        </div >
    }

    return (
        <div className='injury'>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Card style={{ borderRadius: '8px' }} title={getCardTitle('基本信息')} size="small">
                    <BaseForm
                        basicInfo={injuryInfo?.basicInfo}
                        enumInfo={enumInfo}
                        treeData={treeData}
                        formDisabled={formDisabled}
                        updateResponsibilityRatio={updateResponsibilityRatio}
                        updateLossTypeCode={(val) => { setShowInjuryForm(val) }}
                        updateCalculateOrder={updateCalculateOrder}
                        ref={BaseFormRef}
                    />
                </Card>
                <Card style={{ borderRadius: '8px' }} title={getCardTitle('赔偿标准信息')} extra={getCardFoldExtra()} size="small">
                    <div style={{ height: isFold ? 0 : 'auto', visibility: isFold ? 'hidden' : 'visible', overflow: 'hidden' }}>
                        <PayForm
                            compensationStandardInfoModel={injuryInfo?.compensationStandardInfoModel}
                            enumInfo={enumInfo}
                            treeData={treeData}
                            formDisabled={formDisabled}
                            updateAreaCode={updateAreaCode}
                            updateCalculateOrder={updateCalculateOrder}
                            updateDisLevelRatio={() => { setIsUpdateAreaCode(true) }}
                            ref={PayFormRef}
                        />
                    </div>

                </Card>
                <Card style={{ borderRadius: '8px' }} title={getCardTitle('医院信息')} size="small" extra={getCardExtra(HospitalFormRef)}>
                    <HospitalForm
                        hospitalModel={injuryInfo?.hospitalModel}
                        enumInfo={enumInfo}
                        formDisabled={formDisabled}
                        updateCalculateOrder={updateCalculateOrder}
                        ref={HospitalFormRef}
                    />
                </Card>
                <Card style={{ borderRadius: '8px' }} title={getCardTitle('护理人信息（可多条）')} size="small" extra={getCardExtra(CareFormRef)}>
                    <CareForm
                        caregiverInfoModelList={injuryInfo?.caregiverInfoModelList}
                        enumInfo={enumInfo}
                        formDisabled={formDisabled}
                        updateCalculateOrder={updateCalculateOrder}
                        ref={CareFormRef}
                    />
                </Card>
                <Card style={{ borderRadius: '8px' }} title={getCardTitle('被抚养人信息（可多条）')} size="small" extra={getCardExtra(DependentFormRef)}>
                    <DependentForm
                        dependentInfoModelList={injuryInfo?.dependentInfoModelList}
                        enumInfo={enumInfo}
                        formDisabled={formDisabled}
                        updateCalculateOrder={updateCalculateOrder}
                        ref={DependentFormRef}
                    />
                </Card>
                <Card style={{ borderRadius: '8px' }} title={getCardTitle('诊断信息（可多条）')} size="small" extra={getCardExtra(DiagnosticFormRef)}>
                    <DiagnosticForm
                        diagnosticInfoModelList={injuryInfo?.diagnosticInfoModelList}
                        enumInfo={enumInfo}
                        formDisabled={formDisabled}
                        updateDiagnosticInfo={updateDiagnosticInfo}
                        ref={DiagnosticFormRef}
                    />
                </Card>
                <Card style={{ borderRadius: '8px' }} title={getCardTitle('三期天数')} size="small">
                    <ThreePhaseForm
                        threeLimitInfoModel={injuryInfo?.threeLimitInfoModel}
                        formDisabled={formDisabled}
                        diagnosticInfo={diagnosticInfo}
                        updateCalculateOrder={updateCalculateOrder}
                        updateDiagnosticInfo={updateDiagnosticInfo}
                        ref={ThreePhaseFormRef}
                    />
                </Card>
                {
                    showInjuryForm && <Card style={{ borderRadius: '8px' }} title={getCardTitle('伤残信息（可多条）')} size="small" extra={getCardExtra(InjuryFormRef)}>
                        <InjuryForm
                            disabledInfoModel={injuryInfo?.disabledInfoModel}
                            formDisabled={formDisabled}
                            areaCode={areaCode}
                            isUpdateAreaCode={isUpdateAreaCode}
                            updateCalculateOrder={updateCalculateOrder}
                            updateDisLevelRatio={() => { setIsUpdateAreaCode(false) }}
                            ref={InjuryFormRef}
                        />
                    </Card>
                }

                <Card style={{ borderRadius: '8px' }} title={getCardTitle('定损标准')} size="small">
                    <StandardForm
                        lossOrderInfoModel={injuryInfo?.lossOrderInfoModel}
                        enumInfo={enumInfo}
                        formDisabled={formDisabled}
                        responsibilityRatio={responsibilityRatio}
                        ref={StandardFormRef}
                    />
                </Card>
                <div style={{ height: '100px' }}></div>
            </Space>

            <div className="footer">
                {
                    action === 'detail' && <Button className="button" onClick={() => history.goBack()}
                    >
                        返回
                    </Button>
                }
                <Button className="button" type="primary" href={`./agreement?caseId=${id}`}
                >
                    电子协议书
                </Button>
                <Button className="button" type="primary" href={`./media?caseId=${id}`}
                >
                    影像信息
                </Button>
                {
                    action !== 'detail' && <>
                        <Button className="button" type="primary" onClick={() => { setSendModalValue({ id }); setShowSendModal(true); }}
                        >
                            资料收集
                        </Button>
                        <Button className="button" type="primary" onClick={() => updateCalculateOrder(true)}
                        >
                            计算定损单
                        </Button>
                        <Button className="button" type="primary" onClick={() => save()}
                        >
                            保存
                        </Button>
                        <Button className="button" type="primary" onClick={() => { { setTransferModalValue({ id }); setShowTransferModal(true); } }}
                        >
                            转交
                        </Button>
                        <Button className="button" type="primary" onClick={() => preSubmit()}
                        >
                            办结
                        </Button>

                    </>
                }
                <SendModal
                    visible={showSendModal}
                    hideModal={hideSendModal}
                    value={sendModalValue}
                />
                <TransferModal
                    visible={showTransferModal}
                    hideModal={hideCreateModal}
                    value={transferModalValue}
                />
            </div>
        </div>

    )
}
export default withRouter(HomePage);