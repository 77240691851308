import React from 'react';
import { withRouter } from 'react-router-dom';
import { Result, Button } from 'antd';

function Page404() {
  const goHome = () => {
    // history.replace('/');
  };

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button type="primary" onClick={goHome}>回到首页</Button>}
    />
  );
}


export default withRouter(Page404);
