import { POST, GET } from '../../utils/request';

export async function login(payload) {
  const res = await POST(
    '/api/@injury/sysAdmin/login',
    payload
  );
  return res;
}

export async function sendCodeMessage(mobile) {
  const res = await GET(
    `/api/@injury/sysAdmin/sendSubmitCode?mobile=${mobile}`);
  return res;
}
