import ImgsViewer from "react-images-viewer";
export default function ImagePreview({
    isShow = false,
    toggle = () => { },
    index = 0,
    images = [],
    nextImg = () => { },
    preImg = () => { },
}) {

    return (
        <ImgsViewer
            imgs={images}
            currImg={index}
            isOpen={isShow}
            onClickPrev={preImg}
            onClickNext={nextImg}
            onClose={toggle}
        />
    )
}

