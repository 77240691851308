import React, { useLayoutEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import {
  Form,
  InputNumber,
  Select,
  Divider,
  Row,
  Col
} from 'antd';
import _ from 'lodash';
import { getCareInfo } from '../config'

const getOptions = optionsMap => Object.keys(optionsMap || {})?.map(key => (
  <Select.Option
    key={`${optionsMap[key]}-${key}`}
    value={key}
  >
    {optionsMap[key]}
  </Select.Option>
));


const CareForm = ({
  caregiverInfoModelList = {},
  enumInfo = {},
  formDisabled = false,
}, ref) => {
  const [form] = Form.useForm()
  const [caregiverRelationMap, setCaregiverRelationMap] = useState({}) // 护理人身份枚举

  useLayoutEffect(() => {
    form.setFieldsValue({ itemList: [getCareInfo()] })
    if (caregiverInfoModelList?.length) {
      form.setFieldsValue({ itemList: caregiverInfoModelList || [getCareInfo()] })
      // 表单新建或编辑时，需要确认一些表单项的初始显隐状态
    }
    if (enumInfo) {
      setCaregiverRelationMap(enumInfo.caregiverRelationMap)
    }
  }, [caregiverInfoModelList, enumInfo]);


  useImperativeHandle(ref, () => ({
    myForm: form,
    handleAdd: handleAdd,
    getData: getData
  }));

  const handleAdd = () => {
    const { itemList } = form.getFieldsValue()
    form.setFieldsValue({ itemList: [...itemList, getCareInfo()] })
  }

  const getData = () => {
    const itemList = form.getFieldsValue()?.itemList;
    if (itemList?.length < 2 && _.isEqual(itemList[0], getCareInfo())) {
      return null
    }
    return itemList
  }


  return (
    <>
      <Form
        form={form}
        name="CareForm"
        disabled={formDisabled}
      >
        <Form.List name='itemList'>
          {(fields, { add, remove }) =>
            fields.map((field, index) => (
              <div key={index}>
                {
                  index !== 0 && <Divider />
                }
                <Row gutter={24} >
                  <Col span={6} >
                    <Form.Item
                      {...field}
                      name={[field.name, 'relationCode']}
                      label="护理人身份"
                      rules={[{ required: true, message: '请选择护理人身份!' }]}
                    >
                      <Select placeholder="请选择" >
                        {getOptions(caregiverRelationMap)}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6} >
                    <Form.Item
                      {...field}
                      name={[field.name, 'industryIncome']}
                      label="月收入"
                      rules={[{ required: true, message: '请输入月收入!' }, { type: 'number', min: 0, message: '请输入大于0的数字' }]}
                    >
                      <InputNumber placeholder="请输入" style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={6} >
                  </Col>
                  <Col span={6}> {
                    (fields?.length > 1 && !formDisabled) && <div className='del-btn' onClick={() => remove(field.name)}>删除</div>
                  }
                  </Col>
                </Row>
              </div>

            ))
          }
        </Form.List>
      </Form>
    </>
  );
};

export default forwardRef(CareForm);
