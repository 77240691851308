import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import {
  Form,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Divider,
  Row,
  Col
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { getDependentInfo } from '../config'
import { queryDependentYears } from '../service'

const oneYear = 365 * 24 * 60 * 60 * 1000;

const getOptions = optionsMap => Object.keys(optionsMap || {})?.map(key => (
  <Select.Option
    key={`${optionsMap[key]}-${key}`}
    value={key}
  >
    {optionsMap[key]}
  </Select.Option>
));


const DependentForm = ({
  dependentInfoModelList,
  enumInfo = {},
  formDisabled = false,
  updateCalculateOrder = () => { }
}, ref) => {
  const [form] = Form.useForm();
  const [victimRelationMap, setVictimRelationMap] = useState({}) // 与受害人关系


  useImperativeHandle(ref, () => ({
    myForm: form,
    handleAdd: handleAdd,
    getData: getData
  }));

  const handleAdd = () => {
    const { itemList } = form.getFieldsValue()
    form.setFieldsValue({ itemList: [...itemList, getDependentInfo()] })
  }

  const getData = () => {
    const itemList = form.getFieldsValue()?.itemList;
    if (itemList?.length < 2 && _.isEqual(itemList[0], getDependentInfo())) {
      return null
    }
    return itemList
  }

  useEffect(() => {
    form.setFieldsValue({ itemList: [getDependentInfo()] })
    if (dependentInfoModelList?.length) {
      form.setFieldsValue({
        itemList: dependentInfoModelList?.map(item => ({
          ...item,
          dependentBirthday: item.dependentBirthday ? moment(item.dependentBirthday) : null,
        }))
      });
    }
    if (enumInfo) {
      setVictimRelationMap(enumInfo.victimRelationMap)
    }
  }, [dependentInfoModelList, enumInfo]);

  // 表单更新
  const onFormLayoutChange = async (values, allValues) => {
    if (!values) return;

    const updateList = allValues.itemList;

    const idx = values.itemList.findIndex(item => item)
    const updateItem = values.itemList.filter(item => item)
    // form list 删除场景处理
    const isDelete = Object.keys(updateItem[0])?.length > 1;
    if (isDelete) return

    console.log(values, allValues, updateItem);
    if (!updateItem.length) return;
    if (updateItem[0].dependentBirthday) {
      const age = Math.floor((+new Date() - +moment(updateItem[0].dependentBirthday)) / oneYear)

      updateList[idx].dependentAge = age
      // 计算抚养年限
      const res = await queryDependentYears(age) || 0;
      updateList[idx].dependentYearTotal = res.data
      // 先更新表单 更新定损单
      form.setFieldsValue({ itemList: updateList });
      // 更新定损单
      updateCalculateOrder();
    }
    else if (updateItem[0].dependentCount) {
      let count = Math.floor(100 / updateItem[0].dependentCount * 100) / 100.
      count = count.toFixed(2);
      updateList[idx].dependentShare = count
      // 更新定损单
      updateCalculateOrder();
    }

    form.setFieldsValue({ itemList: updateList });
    console.log('change', values, updateList);
  }

  // 可选择日期不能大于今天
  const disabledDate = (current) => {
    return current && current > +new Date();
  };


  return (
    <>
      <Form
        form={form}
        name="DependentForm"
        disabled={formDisabled}
        onValuesChange={onFormLayoutChange}
      >
        <Form.List name='itemList'>
          {(fields, { add, remove }) =>
            fields?.map((field, index) => (
              <div key={index}>
                {
                  index !== 0 && <Divider />
                }
                <Row gutter={24} key={index}>
                  <Col span={6} >
                    <Form.Item
                      name={[field.name, 'dependentName']}
                      label="姓名"
                      rules={[{ required: true, message: '请输入姓名!' }]}
                    >
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={6} >
                    <Form.Item
                      name={[field.name, 'victimRelationCode']}
                      label="与受害者关系"
                      rules={[{ required: true, message: '请选择与受害者关系!' }]}
                    >
                      <Select placeholder="请选择" >
                        {getOptions(victimRelationMap)}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6} >
                    <Form.Item
                      name={[field.name, 'dependentBirthday']}
                      label="出生日期"          
                      rules={[{ required: true, message: '请输入出生日期!' }]}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'YYYY/MM/DD'}
                        placeholder={'出生日期'}
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6} >
                    <Form.Item
                      name={[field.name, 'dependentAge']}
                      label="年龄"
                    >
                      <Input disabled placeholder="请输入" suffix='周岁' />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={6} >
                    <Form.Item
                      name={[field.name, 'dependentCount']}
                      label="共同抚养人数"
                      rules={[{ required: true, message: '请输入共同抚养人数!' }, { type: 'number', min: 0, message: '请输入大于0的数字' }]}
                    >
                      <InputNumber placeholder="请输入" style={{ width: '100%' }} />

                    </Form.Item>
                  </Col>
                  <Col span={6} >
                    <Form.Item
                      name={[field.name, 'dependentShare']}
                      label="抚养比例"
                    >
                      <Input disabled placeholder="请输入" suffix='%' />
                    </Form.Item>
                  </Col>
                  <Col span={6} >
                    <Form.Item
                      name={[field.name, 'dependentYearTotal']}
                      label="抚养年限"
                    >
                      <Input disabled placeholder="请输入" suffix='年' />
                    </Form.Item>
                  </Col>
                  <Col span={6}> {
                    (fields?.length > 1 && !formDisabled) && <div className='del-btn' onClick={() => remove(field.name)}>删除</div>
                  }
                  </Col>
                </Row>
              </div>
            ))
          }
        </Form.List>

      </Form>
    </>

  );
};

export default forwardRef(DependentForm);
