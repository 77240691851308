import React, { useState } from 'react';
import { Layout, Menu, Modal, Avatar } from 'antd';
import {
  TrophyOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { withRouter } from 'react-router-dom';
import iconExit from '../../assets/ic_exit.png'
import './index.less';

const { Header, Sider, Content } = Layout;
const { confirm } = Modal;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}



function Template({ Component, title = '', history, user = {} }) {

  console.log('[userInfo]', user);

  const isAdmin = user.role === 1;
  const items = [
    getItem('人伤列表', '/personInjury/list', <TrophyOutlined />),
    isAdmin ? getItem('人员管理', '/user-config', <UserOutlined />) : null
  ]
  
  const [show, setShow] = useState(true)
  const [collapsed, setCollapsed] = useState(false);
  const [subKey, setSubKey] = useState(['root-data', 'performance', 'operator-data']);

  const clickMenu = e => {
    history.push(e.key);
  };

  const defaultPath = window.location.pathname;

  const onOpenChange = e => {
    setSubKey(e);
  };


  const exit = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: `确认退出账号？`,
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        sessionStorage.clear();
        window.location.href = '/login';
      },
    });
  };

  return (
    <Layout className='root-view'>
      <Sider trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}>
        <div className="logo">
          {
            show && <> {collapsed ? '理' : '理赔通系统'}</>
          }

        </div>
        <div className='avatar'>
          {
            show && <>           {
              !collapsed && <>
                <Avatar size={36} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                <div className='name'>{user?.name}</div>
              </>
            }</>
          }


        </div>


        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[defaultPath]}
          onClick={clickMenu}
          openKeys={subKey}
          onOpenChange={onOpenChange}
          style={{ height: '70vh', overflowY: 'scroll', position: 'sticky', top: 0 }}
          items={items}
        >
        </Menu>
        <div className='exit' onClick={exit}>
          <img width={20} src={iconExit}></img>
          {
            show && <>
              {
                !collapsed && <div className='txt'>退出账号</div>
              }</>
          }
        </div>
      </Sider>
      <Layout style={{ marginLeft: collapsed ? 80 : 200, minHeight: '100%' }}>
        <Header style={{ padding: 0, background: '#FFFFFF' }}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: () => {
              setShow(false);
              setTimeout(() => {
                setShow(true);
              }, 200)
              setCollapsed(!collapsed)
            },
          })}
          <span style={{ marginLeft: '16px', fontWeight: 600 }}>{title}</span>
        </Header>
        <Content style={{ margin: '24px 16px', minHeight: '280px' }}>
          <Component user={user} />
        </Content>
      </Layout>
    </Layout>
  );
}

export default withRouter(Template);
