import React, { useLayoutEffect, useState, useImperativeHandle, forwardRef } from 'react';
import {
  Form,
  Input,
  InputNumber,
  Select,
  Row,
  Col,
  Table,
  message
} from 'antd';
import _ from 'lodash';
import { getInjuryList } from '../config';
import { generateId, isEmpty } from '../../../utils'
import { queryDisabledBody, queryDisabled, queryAppraisalOrg, computeDisabledComposition } from '../service'

const isItemEqual = (a, b, key) => {
  if (!a) return false;
  delete a[key]
  delete b[key]
  return _.isEqual(a, b)
}


const InjuryForm = ({
  disabledInfoModel = {},
  formDisabled = false,
  areaCode = [],
  isUpdateAreaCode = false,
  updateCalculateOrder = () => { },
  updateDisLevelRatio=()=>{}
}, ref) => {
  const [form] = Form.useForm();
  const [AppraisalOrgOptions, setAppraisalOrgOptions] = useState([]) // 鉴定机构编码
  const [dataSource, setDataSource] = useState([getInjuryList()]);

  useImperativeHandle(ref, () => ({
    myForm: form,
    handleAdd: handleAdd,
    getData: getData,
    validate: validate
  }));

  const getData = () => {
    const submitInfo = form.getFieldsValue()
    if (dataSource && dataSource.length < 2) {
      if (isItemEqual(dataSource[0], getInjuryList(), 'rowKey')) {
        return null
      }
    }
    return {
      ...disabledInfoModel,
      ...submitInfo,
      disabledItemModelList: dataSource 
    }
  }

  useLayoutEffect(() => {
    if (disabledInfoModel?.disabledItemModelList) {
      form.setFieldsValue(disabledInfoModel);
      setDataSource(disabledInfoModel?.disabledItemModelList?.map(item => ({
        ...item,
        rowKey: generateId()
      })) || [])
    }
    if(isUpdateAreaCode){
      computeComposition(dataSource)
      updateDisLevelRatio()
    }
  }, [disabledInfoModel]);


  const handleSearchdisabledName = async (newValue, record) => {
    if (newValue) {
      const res = await queryDisabledBody(newValue);
      const updatedData = dataSource.map((item) => ({
        ...item,
        disabledNameOptions: item.rowKey === record.rowKey ? res.data : []
      }))
      setDataSource(updatedData)
      console.log('handleSearchdisabledName', res.data);
    }
  };

  const handleSearchDisDescribe = async (newValue, record) => {
    if (newValue) {
      const disabledBodyName = record.disabledBodyName;
      const name = typeof newValue === 'string' ? newValue : '';
      const res = await queryDisabled(disabledBodyName, name);
      const updatedData = dataSource.map((item) => ({
        ...item,
        disDescribeOptions: item.rowKey === record.rowKey ? res.data : []
      }))
      setDataSource(updatedData)
      console.log('handleSearchDisDescribe', res.data);
    }
  };

  const handleSearchAppraisalOrg = async (newValue) => {
    if (newValue) {
      const res = await queryAppraisalOrg(newValue);
      setAppraisalOrgOptions(res.data || []);
    } else {
      setAppraisalOrgOptions([])
    }
  };

  const computeComposition = (list) => {
    const params = {
      provinceCode: areaCode?.[0],
      cityCode: areaCode?.[1],
      disabledLevelRatioList: list.map((item) => ({ levelCode: item.disLevelCode, ratio: item.disLevelRatio }))
    }
    computeDisabledComposition(params).then(res => {
      if (res.success) {
        form.setFieldsValue({ disabledRatio: res.data || 0})
        // 更新定损单
        updateCalculateOrder();
      } else if (res.error) {
        message.error(res.error?.message)
      }
    })
  }
  // 表单更新
  const onFormLayoutChange = (values) => {
    if (!values) return;
    if (values.relationRatio) {
      // 更新定损单
      updateCalculateOrder();
    }
  }

  const changeValue = (value, key, rowKey) => {
    const updatedData = dataSource.map((item) => {
      if (item.rowKey === rowKey) {
        item[key] = value;
        if (key === 'disLevelDescribe') {
          const disItem = item?.disDescribeOptions?.filter(i => i.disLevelDescribe === value);

          if (disItem?.length) {
            // 更新伤残编码
            item.disabledCode = disItem[0]?.disabledCode
            // 更新伤残系数
            item.disLevelRatio = disItem[0]?.disLevelRatio;
            item.disLevelCode = disItem[0]?.disLevelCode;
          }
        } else if (key === 'disabledBodyName') {
          item.disLevelDescribe = '';
          item.disLevelCode = '';
          item.disLevelRatio = '';
          handleSearchDisDescribe(true, item)
        }
      }
      return item
    })
    // 更新总伤残系数
    if (key === 'disLevelDescribe' && value) {
      computeComposition(updatedData)
    }
    setDataSource(updatedData)
    console.log(`change-${key}`, value, dataSource,)
  }

  const validate = async () => {
    const keys = ['disabledBodyName', 'disLevelDescribe', 'disabledCode', 'disLevelCode', 'disLevelRatio']
    const valid = dataSource.every(data => {
      return Object.keys(data).every(key => {
        if (keys.includes(key)) {
          return !isEmpty(data[key])
        } else {
          return true
        }
      })
    })
    if (!valid) throw new Error('伤残信息未填写完整')
  }


  const handleAdd = () => {
    const newItem = getInjuryList();
    const newData = _.cloneDeep(dataSource);
    setDataSource([...newData, newItem]);
  }

  const handleDelete = (index) => {
    const newData = _.cloneDeep(dataSource);
    newData.splice(index, 1)
    // 更新总伤残系数
    computeComposition(newData)
    setDataSource(newData);
  };

  const columns = [
    {
      title: '伤残部位',
      dataIndex: 'disabledBodyName',
      key: 'disabledBodyName',
      width: 80,
      align: 'center',
      render: (text, record, index) => <div>
        <Select
          value={text === '' ? undefined : text}
          key={index}
          style={{ width: '100%' }}
          placeholder="请选择"
          filterOption={false}
          allowClear
          showSearch
          disabled={formDisabled}
          onSearch={(val) => handleSearchdisabledName(val, record)}
          options={record?.disabledNameOptions?.map((item) => ({
            value: item.disabledBodyName,
            label: item.disabledBodyName,
          }))}
          onChange={(value) => changeValue(value, 'disabledBodyName', record.rowKey)}
        >
        </Select>
      </div>,
    },
    {
      title: '伤残描述',
      dataIndex: 'disLevelDescribe',
      key: 'disLevelDescribe',
      width: 80,
      align: 'center',
      render: (text, record, index) => <div>
        <Select
          disabled={!record.disabledBodyName || formDisabled}
          value={text === '' ? undefined : text}
          key={index}
          style={{ width: '100%' }}
          placeholder="请选择"
          filterOption={false}
          allowClear
          showSearch
          onSearch={(val) => handleSearchDisDescribe(val, record)}
          options={record?.disDescribeOptions?.map((item) => ({
            value: item.disLevelDescribe,
            label: item.disLevelDescribe,
          }))}
          onChange={(value) => changeValue(value, 'disLevelDescribe', record.rowKey)}
        >
        </Select>
      </div>,
    },
    {
      title: '伤残编码',
      dataIndex: 'disabledCode',
      key: 'disabledCode',
      width: 80,
      align: 'center',
      render: (text, record, index) => <div>
        <Input
          value={text === '' ? undefined : text}
          disabled
          placeholder="请输入"
          onChange={(value) => changeValue(value, 'disabledCode', record.rowKey)}
        />
      </div>,
    },
    {
      title: '伤残等级',
      dataIndex: 'disLevelCode',
      key: 'disLevelCode',
      width: 80,
      align: 'center',
      render: (text, record, index) => <div>
        <Input
          value={text === '' ? undefined : text}
          disabled
          placeholder="请输入"
          onChange={(value) => changeValue(value, 'disLevelCode', record.rowKey)}
        />
      </div>,
    },
    {
      title: '伤残系数',
      dataIndex: 'disLevelRatio',
      key: 'disLevelRatio',
      width: 80,
      align: 'center',
      render: (text, record, index) => <div>
        <Input
          value={text === '' ? undefined : text}
          disabled
          placeholder="请输入"
          onChange={(value) => changeValue(value, 'disLevelRatio', record.rowKey)}
        />
      </div>,

    },
    {
      title: '',
      key: 'operate',
      width: 40,
      align: 'center',
      fixed: 'right',
      render: (text, record, index) => !formDisabled && <div className='red' onClick={() => handleDelete(index)}>
        删除
      </div>
    },
  ];

  return (
    <div ref={ref}>
      <Form
        form={form}
        name="InjuryForm"
        disabled={formDisabled}
        onValuesChange={onFormLayoutChange}
      >
        <Row gutter={24}>
          <Col span={6} >
            <Form.Item
              name="relationRatio"
              label="伤残关联"
              rules={[{ required: true, message: '请输入伤残关联!' }, { type: 'number', min: 0, max: 100, message: '请输入大于0,小于100的数字' }]}
            >
              <InputNumber placeholder="请输入" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={6} >
            <Form.Item
              name="disabledRatio"
              label="伤残系数"
              rules={[{ required: true, message: '请输入伤残系数!' }]}
            >
              <Input disabled placeholder="请输入" suffix='%' />
            </Form.Item>
          </Col>
          <Col span={6} >
            <Form.Item
              name="appraisalOrganCode"
              label="鉴定机构"
            >
              <Select
                style={{ width: '100%' }}
                placeholder="请选择"
                filterOption={false}
                allowClear
                showSearch
                onSearch={handleSearchAppraisalOrg}
                options={AppraisalOrgOptions?.map((item) => ({
                  value: item.orgCode,
                  label: item.orgName,
                }))}
              >
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        scroll={{ x: 440 }}
        columns={columns}
        dataSource={dataSource}
        bordered
        rowKey={(record) => record?.rowKey}
        pagination={false}
      ></Table>
    </div>

  );
};

export default forwardRef(InjuryForm);
