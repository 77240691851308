import React, {useEffect,useState} from 'react';
import {
  BrowserRouter, Redirect, Route, Switch
} from 'react-router-dom';
import { getUserInfo } from './services'

import RootView from './page/layout';

// 人伤管理
import PersonInjuryList from './page/personInjury/list'
import PersonInjuryDetail from './page/personInjury/detail'
import PersonInjuryAgreement  from './page/personInjury/agreement';
import PersonInjuryMedia from './page/personInjury/mediaView'

// 用户管理
import PageUserConfig from './page/user-config';

import PageLogin from './page/login';
import Page404NotFound from './page/404';
import Page403 from './page/403';

function RouterConfig() {

  const [user, setUser] = useState({}); // 用户信息
  if (!sessionStorage.getItem('mobile') && window.location.pathname !== '/login') {
    window.location.href = '/login';
  }

  useEffect( () => {
     getUser();
  }, []);

  const getUser = () => {
    getUserInfo().then(res => {
      if (res.success && res.data) {
        console.log('user',res.data);
        setUser(res.data)
      } 
    })
  }

  if (!sessionStorage.getItem('mobile') && window.location.pathname !== '/login') {
    window.location.href = '/login';
  }


  const getComp = path => {
    switch (path) {
      case '/personInjury/list':
        return PersonInjuryList;
      case '/personInjury/detail':
        return PersonInjuryDetail;
      case '/personInjury/agreement':
        return PersonInjuryAgreement;
        case '/personInjury/media':
          return PersonInjuryMedia;
      case '/user-config':
        return PageUserConfig;
      case '/':
        return PageLogin;
      default:
        return PersonInjuryList;
    }
  };

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={PageLogin} />
        <Redirect exact path="/" to="/home" />
        <Route exact path="/personInjury/list" render={() => (<RootView user={user} path="/" title="人伤列表" Component={getComp('/personInjury/list')} />)} />
        <Route exact path="/personInjury/detail" render={() => (<RootView user={user} path="/" title="人伤详情" Component={getComp('/personInjury/detail')} />)} />
        <Route exact path="/personInjury/agreement" render={() => (<RootView user={user} path="/" title="电子协议书" Component={getComp('/personInjury/agreement')} />)} />
        <Route exact path="/personInjury/media" render={() => (<RootView user={user} path="/" title="影像信息" Component={getComp('/personInjury/media')} />)} />
        <Route exact path="/user-config" render={() => (<RootView user={user} path="/" title="人员管理" Component={getComp('/user-config')} />)} />
        <Route render={() => (<RootView user={user} path="/404" Component={Page404NotFound} />)} />
        <Route render={() => (<RootView user={user} path="/403" Component={Page403} />)} />
      </Switch>
    </BrowserRouter>
  );
}

export default RouterConfig;
