
export const OpreateTypeMap = {
    1: "案件导入",
    2: "案件认领",
    3: "编辑案件",
    4: "转交案件",
    5: "影像收集",
    6: "发送调解协议书",
    7: "办结案件",
    8: "创建用户",
    9: "禁用用户",
    10: "编辑用户"
}