import React, { useEffect, useState } from 'react';
import {
    Form,
    Select,
    Input,
    Modal,
    message,
} from 'antd';
import { transferInjury, queryByName } from '../service';


const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};

const TransferModal = ({
    visible,
    hideModal,
    value
}) => {
    const [form] = Form.useForm();
    const [nameOptions, setNameOptions] = useState([]) // 姓名options
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (value && value.id) {
            form.setFieldsValue(value);
        }
    }, [value]);

    // 表单更新
    const onFormLayoutChange = async (values) => {
        if (!values) return;
        if (values.name) {
            const filterItem = nameOptions?.filter(i => i.name === values.name);
            let mobile = filterItem[0]?.mobile
            form.setFieldsValue({ mobile })
        }
        console.log('change', values);
    }

    const handleSearchName = async (name) => {
        if (name) {
            const res = await queryByName(name);
            setNameOptions(res.data || [])
            console.log('handleSearchName', res.data);
        }
    }

    const onFinish = values => {
        const mobile = form.getFieldValue('mobile');
        if (!mobile || mobile.length !== 11) {
            message.error('请检查手机号');
            return;
        }
        setLoading(true);
        const payload = {
            ...values,
        };
        transferInjury(payload).then(res => {
            if (res.success) {
                message.success('操作成功');
                // 校验通过
                form.resetFields();
                hideModal();
            } else if (res.error) {
                message.error(res.error?.message);
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            message.error('操作失败');
        });
    }


    return (
        <Modal
            title='案件转交'
            visible={visible}
            onCancel={() => { hideModal(); form.resetFields(); }}
            onOk={() => { form.submit(); }}
            okText="提交"
            cancelText="取消"
            width={600}
            loading={loading}
        >
            <Form
                {...formItemLayout}
                form={form}
                name="transfer"
                onValuesChange={onFormLayoutChange}
                onFinish={onFinish}
            >
                <Form.Item
                    name="id"
                    label="案件ID"
                >
                    <Input disabled style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                    name="name"
                    label="姓名"
                    rules={[{ required: true, message: '请输入姓名!', }]}
                >
                    <Select

                        style={{ width: '100%' }}
                        placeholder="请选择"
                        filterOption={false}
                        allowClear
                        showSearch
                        onSearch={(val) => handleSearchName(val)}
                        options={nameOptions?.map((item) => ({
                            value: item.name,
                            label: item.name,
                        }))}
                    >
                    </Select>
                </Form.Item>

                <Form.Item
                    name="mobile"
                    label="手机号"
                    rules={[{ required: true, message: '请输入手机号!', }]}
                >
                    <Input disabled style={{ width: '100%' }} />
                </Form.Item>

            </Form>
        </Modal>
    );
};

export default TransferModal;
