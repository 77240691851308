import React, { useLayoutEffect, useState, useImperativeHandle, forwardRef } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Col
} from 'antd';
import { queryCompensationStandard, queryYear } from '../service'

import { getDefaultPayInfo, formatLeafTreeData } from '../config'
import CitySelect from './CitySelect'


const getOptions = optionsMap => Object.keys(optionsMap || {})?.map(key => (
  <Select.Option
    key={`${optionsMap[key]}-${key}`}
    value={key}
  >
    {optionsMap[key]}
  </Select.Option>
));


const PayForm = ({
  compensationStandardInfoModel = null,
  enumInfo = {},
  treeData = {},
  formDisabled = false,
  updateAreaCode = () => { },
  updateCalculateOrder = () => { },
  updateDisLevelRatio = () => { }
}, ref) => {

  const [form] = Form.useForm();
  const [payYear, setPayYear] = useState([])
  const [householdNatureMap, setHouseholdNatureMap] = useState({})

  useImperativeHandle(ref, () => ({
    myForm: form,
    getData: getData
  }));

  useLayoutEffect(() => {
    form.setFieldsValue(getDefaultPayInfo())
    if (compensationStandardInfoModel && Object.keys(compensationStandardInfoModel).length) {
      form.setFieldsValue({
        ...compensationStandardInfoModel,
        areaCode: compensationStandardInfoModel?.applyProAddressCode ? [+compensationStandardInfoModel?.applyProAddressCode, +compensationStandardInfoModel?.applyCityAddressCode] : null
      });
      if (compensationStandardInfoModel?.applyProAddressCode) {
        getPayYear(compensationStandardInfoModel?.applyProAddressCode)
      }
    }
    if (enumInfo) {
      setHouseholdNatureMap(enumInfo.householdNatureMap)
    }
  }, [compensationStandardInfoModel, enumInfo]);

  const getData = () => {
    const submitInfo = form.getFieldsValue()
    return {
      ...compensationStandardInfoModel,
      ...submitInfo,
      applyProAddressCode: submitInfo?.areaCode?.[0],
      applyCityAddressCode: submitInfo?.areaCode?.[1]
    }
  }

  // 赔偿年度list
  const getPayYearOtions = () => {
    return payYear?.map(item => (
      <Select.Option
        key={item}
        value={item}
      >
        {item}年
      </Select.Option>
    ));
  }

  // 表单更新
  const onFormLayoutChange = async (values) => {
    if (!values) return;
    if (values.compensateYear) {
      await getPayInfo(values.compensateYear)
      // 更新定损单
      updateCalculateOrder();
      // 更新伤残系数
      updateDisLevelRatio()
    } else if (values.areaCode) {
      // 重置表单
      const { householdNatureCode } = form.getFieldsValue()
      form.resetFields();
      form.setFieldsValue({ areaCode: values.areaCode, householdNatureCode })
      // 更新赔偿年份list
      getPayYear(values.areaCode[0])
      // 更新外层赔偿地
      updateAreaCode(values.areaCode);
    }
    console.log('change', values);
  }

  const getPayInfo = async (year, area) => {
    const areaCode = area ? area : form.getFieldsValue().areaCode;;
    const res = await queryCompensationStandard(areaCode?.[0], areaCode?.[1], year)
    form.setFieldsValue(res.data);
  }

  const getPayYear = (city) => {
    queryYear(city).then(res => {
      if (res.success && res.data) {
        setPayYear(res.data)
      }
    })
  }

  return (
    <Form
      form={form}
      name="payForm"
      disabled={formDisabled}
      onValuesChange={onFormLayoutChange}
    >
      <Row gutter={24}>
        <Col span={6} >
          <Form.Item
            name="areaCode"
            label="赔偿适用地"
            rules={[{ required: true, message: '请选择赔偿适用地!' }]}
          >
            <CitySelect treeData={formatLeafTreeData(treeData)} />
          </Form.Item>
        </Col>
        <Col span={6} >
          <Form.Item
            name="compensateYear"
            label="赔偿年度"
            rules={[{ required: true, message: '请选择赔偿年度!' }]}
          >
            <Select placeholder="请选择">
              {getPayYearOtions()}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} >
          <Form.Item
            name="householdNatureCode"
            label="户籍性质"
            rules={[{ required: true, message: '请选择户籍性质!' }]}
          >
            <Select placeholder="请选择" >
              {getOptions(householdNatureMap)}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} >
          <Form.Item
            name="peopleYearClaim"
            label="在岗职工平均工资"
          >
            <Input disabled placeholder="请输入" suffix='元' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6} >
          <Form.Item
            name="urbanIncome"
            label="居民可支配收入"
          >
            <Input disabled placeholder="请输入" suffix='元/年' />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="missedWorkFee"
            label="误工费"
          >
            <Input disabled placeholder="请输入" suffix='元/天' />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="lodgingFee"
            label="住宿费"
          >
            <Input disabled placeholder="请输入" suffix='元/天' />
          </Form.Item>
        </Col>
        <Col span={6} >
          <Form.Item
            name="inHospitalMealFee"
            label="住院伙食费补助"
          >
            <Input disabled placeholder="请输入" suffix='元/天' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6} >
          <Form.Item
            name="urbanExpend"
            label="居民消费性支出"
          >
            <Input disabled placeholder="请输入" suffix='元/年' />
          </Form.Item>
        </Col>
        <Col span={6} >
          <Form.Item
            name="nutritionFee"
            label="营养费"
          >
            <Input disabled placeholder="请输入" suffix='元/年' />
          </Form.Item>
        </Col>
        <Col span={6} >
          <Form.Item
            name="trafficFee"
            label="交通费"
          >
            <Input disabled placeholder="请输入" suffix='元/年' />
          </Form.Item>
        </Col>
        <Col span={6} >
          <Form.Item
            name="inHospitalCaseFee"
            label="护理费"
          >
            <Input disabled placeholder="请输入" suffix='元/年' />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default forwardRef(PayForm);
