import React, { useLayoutEffect, useState, useImperativeHandle, forwardRef } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Table
} from 'antd';
import _ from 'lodash'
import moment from 'moment';
import HospitalSelect from './HospitalSelect'
import { getHospitalTableInfo } from '../config'
import { isEmpty, generateId } from '../../../utils'



const HospitalForm = ({
  hospitalModel,
  formDisabled = false,
  updateCalculateOrder = () => { }
}, ref) => {

  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([getHospitalTableInfo()]);


  useImperativeHandle(ref, () => ({
    myForm: form,
    handleAdd: handleAdd,
    getData: getData,
    validate: validate
  }));

  const validate = () => {
    const keys = ['hospitalCode', 'hospitalName', 'inHospitalDate', 'leaveHospitalDate', 'hospitalStay']
    const valid = dataSource.every(data => {
      return Object.keys(data).every(key => {
        if (keys.includes(key)) {
          return !isEmpty(data[key])
        } else {
          return true
        }
      })
    }
    )
    if (!valid) throw new Error('医院信息未填写完整')
  }

  const getData = () => {
    const submitInfo = form.getFieldsValue()
    return {
      ...hospitalModel,
      ...submitInfo,
      hospitalInfoModelList: dataSource
    }
  }


  useLayoutEffect(() => {
    if (hospitalModel) {
      form.setFieldsValue(hospitalModel);
      console.log('hospitalModel', hospitalModel);
      setDataSource(hospitalModel?.hospitalInfoModelList?.map(item => ({
        ...item,
        inHospitalDate: item?.inHospitalDate ? moment(item.inHospitalDate) : null,
        leaveHospitalDate: item?.leaveHospitalDate ? moment(item.leaveHospitalDate) : null,
        rowKey: generateId()
      })))
    }
  }, [hospitalModel]);

  const changeValue = (value, key, rowKey) => {
    const updatedData = dataSource.map((item) => {
      if (item.rowKey === rowKey) {
        item[key] = value;
        if (key === 'leaveHospitalDate') {
          item.hospitalStay = Math.round((value - item.inHospitalDate) / (24 * 3600 * 1000))
        } else if (key === 'hospitalCode') {
          item.hospitalCode = value.code
          item.hospitalName = value.name
        } else if (key === 'inHospitalDate') {
          item.leaveHospitalDate = null;
          item.hospitalStay = 0;
        }
      }
      return item
    })
    getSumDay(updatedData)
    setDataSource(updatedData)
    console.log(`change-${key}`, value, dataSource,)
  }

  // 表单更新
  const onFormLayoutChange = (values) => {
    if (!values) return;
  }

  // 计算总住院天数
  const getSumDay = (list) => {
    const daysTmp = form.getFieldValue('days')
    let days = 0;
    list?.map(item => {
      days += item.hospitalStay
    })
    if (daysTmp !== days) {
      // 更新总住院天数
      form.setFieldsValue({ days: days });
      // 更新定损单
      updateCalculateOrder();
    }

  }

  // 住院禁用日期
  const disabledInDate = (current, record) => {
    return current && current > + new Date();
  };

  // 出院禁用日期
  const disabledOutDate = (current, record) => {
    return current && (current < record.inHospitalDate || current > + new Date());
  };

  const getColumnTitle = (value) => {
    return <div className='table-title'>{value}</div>;
  }

  const handleAdd = () => {
    const newItem = getHospitalTableInfo();
    const newData = _.cloneDeep(dataSource);
    setDataSource([...newData, newItem]);
  }

  const handleDelete = (index) => {
    const newData = _.cloneDeep(dataSource);
    newData.splice(index, 1)
    // 删除一行时减去对应的住院天数
    getSumDay(newData)
    setDataSource(newData);
  };

  const columns = [
    {
      title: getColumnTitle('就诊医院'),
      dataIndex: 'hospitalCode',
      key: 'hospitalCode',
      width: 100,
      align: 'center',
      render: (text, record, index) => <div>
        <HospitalSelect hospitalCode={record.hospitalCode} hospitalName={record.hospitalName} disabled={formDisabled} updateHospitalCode={(value) => changeValue(value, 'hospitalCode', record.rowKey)} />
      </div>,
    },

    {
      title: '住院日期',
      dataIndex: 'inHospitalDate',
      key: 'inHospitalDate',
      width: 100,
      align: 'center',
      render: (text, record, index) => <div>
        <DatePicker
          allowClear={false}
          style={{ width: '100%' }}
          value={text}
          format={'YYYY/MM/DD'}
          placeholder={'住院日期'}
          disabled={formDisabled}
          disabledDate={(current) => disabledInDate(current, record)}
          onChange={(value) => changeValue(value, 'inHospitalDate', record.rowKey)}
        />
      </div>
    },
    {
      title: '出院日期',
      dataIndex: 'leaveHospitalDate',
      key: 'leaveHospitalDate',
      width: 100,
      align: 'center',
      render: (text, record, index) => <div>
        <DatePicker
          allowClear={false}
          disabled={!record.inHospitalDate || formDisabled}
          style={{ width: '100%' }}
          value={text}
          format={'YYYY/MM/DD'}
          placeholder='出院日期'
          disabledDate={(current) => disabledOutDate(current, record)}
          onChange={(value) => changeValue(value, 'leaveHospitalDate', record.rowKey)}
        />
      </div>
    },
    {
      title: '住院天数',
      dataIndex: 'hospitalStay',
      key: 'hospitalStay',
      width: 100,
      align: 'center',
      render: (text, record, index) => text ? text : ''
    },
    {
      title: '',
      key: 'operate',
      width: 40,
      align: 'center',
      fixed: 'right',
      render: (text, record, index) => (dataSource?.length > 1 && !formDisabled) && <div className='red' onClick={() => handleDelete(index)}>
        删除
      </div>
    },
  ];



  return (
    <div ref={ref}>
      <Form
        form={form}
        name="HospitalForm"
        onValuesChange={onFormLayoutChange}
        disabled={formDisabled}
      >
        <Row gutter={24}>
          <Col span={6} >
            <Form.Item
              name="days"
              label="总住院天数"
            >
              <Input disabled placeholder="总住院天数" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        scroll={{ x: 440 }}
        columns={columns}
        dataSource={dataSource}
        bordered
        rowKey={(record) => record?.rowKey}
        pagination={false}
      ></Table>
    </div>

  );
};

export default forwardRef(HospitalForm);
