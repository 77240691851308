import React, { useLayoutEffect, useState, useImperativeHandle, forwardRef } from 'react';
import {
  Table
} from 'antd';
import { isEmpty } from '../../../utils'
import { queryThreeLimit } from '../service'

const formatThreePahseInfo = (info = {}) => {
  return [{
    desc: '误工期',
    high: info?.missedWorkDaysHigh || '',
    low: info?.missedWorkDaysLow || '',
    used: info?.missedWorkDays || '',
    rowKey: '误工期',
  },
  {
    desc: '营养期',
    high: info?.nutritionDaysHigh || '',
    low: info?.nutritionDaysLow || '',
    used: info?.nutritionDays || '',
    rowKey: '营养期',
  },
  {
    desc: '护理期',
    high: info?.careDaysHigh || '',
    low: info?.careDaysLow || '',
    used: info?.careDays || '',
    rowKey: '护理期',
  }]
}

const BaseForm = ({
  threeLimitInfoModel = {},
  formDisabled = false,
  diagnosticInfo = null,
  updateCalculateOrder = () => { },
  updateDiagnosticInfo=()=>{}
}, ref) => {

  const [innerThreePahseInfo, setInnerThreePahseInfo] = useState(threeLimitInfoModel);
  const [dataSource, setDataSource] = useState(formatThreePahseInfo(threeLimitInfoModel));

  useImperativeHandle(ref, () => ({
    getData: getData
  }));

  const getData = () => {
    return innerThreePahseInfo.careDaysHigh ? innerThreePahseInfo : null
  }

  useLayoutEffect(() => {
    if (threeLimitInfoModel?.id) {
      setInnerThreePahseInfo(threeLimitInfoModel)
      setDataSource(formatThreePahseInfo(threeLimitInfoModel))
    }
    if (diagnosticInfo?.bodyName) {
      getThreePahseInfo(diagnosticInfo)
      // 清除诊断信息，避免重复请求
      updateDiagnosticInfo({})
    }
  }, [threeLimitInfoModel, diagnosticInfo]);


  const getThreePahseInfo = (params) => {
    queryThreeLimit(params).then(res => {
      if (res.data) {
        setInnerThreePahseInfo(res.data)
        setDataSource(formatThreePahseInfo(res.data))
        // 更新定损单
        updateCalculateOrder()
      }
    }).catch(err => {

    });
  }



  const changeValue = (value, index) => {
    const updateItem = innerThreePahseInfo;
    if (index === 0) {
      updateItem.missedWorkDays = value
    } else if (index === 1) {
      updateItem.nutritionDays = value
    } else {
      updateItem.careDays = value
    }
    setInnerThreePahseInfo(updateItem)
    console.log(innerThreePahseInfo, dataSource);
    setDataSource(formatThreePahseInfo(innerThreePahseInfo))
  }

  const columns = [
    {
      title: '描述',
      dataIndex: 'desc',
      key: 'desc',
      width: 60,
      align: 'center',
      className: 'primary primary-bac',
      render: data => !isEmpty(data) ? data : '--',
    },
    {
      title: '国家标准高值',
      dataIndex: 'high',
      key: 'high',
      width: 60,
      align: 'center',
      render: data => !isEmpty(data) ? data : '--',
    },
    {
      title: '国家标准低值',
      dataIndex: 'low',
      key: 'low',
      width: 60,
      align: 'center',
      render: data => !isEmpty(data) ? data : '--',
    },
    {
      title: '定损使用值',
      dataIndex: 'used',
      key: 'used',
      width: 60,
      align: 'center',
      render: data => !isEmpty(data) ? data : '--',
      // render: (text, record, index) => <div>
      //   <InputNumber
      //     style={{ width: '100%' }}
      //     value={text}
      //     placeholder='请输入'
      //     onChange={(e) => changeValue(e, index)}
      //   ></InputNumber>
      // </div>
    }
  ];

  return (
    <>
      <Table
        scroll={{ x: 420 }}
        columns={columns}
        dataSource={dataSource}
        bordered
        rowClassName={
          (record, index) => {
            return index % 2 === 0 ? '' : 'gray-bac'
          }
        }
        rowKey={(record) => record.rowKey}
        pagination={false}
      ></Table>
    </>

  );
};

export default forwardRef(BaseForm);
