import React from 'react';
import { withRouter } from 'react-router-dom';
import { Result } from 'antd';

function Page403() {

  return (
    <Result
      status="403"
      title="403"
      subTitle="对不起，您没有权限访问该页面。"
    />
  );
}

export default withRouter(Page403);
