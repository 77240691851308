import React, { useLayoutEffect, useState, useImperativeHandle, forwardRef } from 'react';
import {
    Select,
    Radio,
    Table
} from 'antd';
import _ from 'lodash';
import { queryInjuryBodyName, queryDiagnoseName, queryDiagnostic, queryIcd10 } from '../service'
import { getDiagnosticInfo } from '../config';
import { generateId, isEmpty } from '../../../utils'


const getOptions = optionsMap => Object.keys(optionsMap || {})?.map(key => (
    <Select.Option
        key={`${optionsMap[key]}-${key}`}
        value={key}
    >
        {optionsMap[key]}
    </Select.Option>
));

const isItemEqual = (a, b, key) => {
    if (!a) return false;
    delete a[key]
    delete b[key]
    console.log(a, b, _.isEqual(a, b));
    return _.isEqual(a, b)
}


const DiagnosticForm = ({
    diagnosticInfoModelList = {},
    enumInfo = {},
    formDisabled = false,
    updateDiagnosticInfo = () => { }
}, ref) => {

    const [disabledMap, setDisabledMap] = useState({})
    const [dataSource, setDataSource] = useState([getDiagnosticInfo()]);
    const [injuryBodyOptions, setInjuryBodyOptions] = useState([])


    useImperativeHandle(ref, () => ({
        handleAdd: handleAdd,
        getData: getData,
        validate: validate
    }));

    const getData = () => {
        if (dataSource?.length < 2 && isItemEqual(dataSource[0], getDiagnosticInfo(), 'rowKey')) {
            return null
        }
        return dataSource
    }

    const validate = async () => {
        const keys = ['bodyName', 'diagnoseName', 'treatmentWayName', 'icd10Code', 'icd10', 'disabled']
        const valid = dataSource.every(data => {
            return Object.keys(data).every(key => {
                if (keys.includes(key)) {
                    return !isEmpty(data[key])
                } else {
                    return true
                }
            })
        }
        )
        if (!valid) throw new Error('诊断信息未填写完整')
    }

    useLayoutEffect(() => {
        if (diagnosticInfoModelList?.length) {
            const list = diagnosticInfoModelList?.map(item => ({
                ...item,
                rowKey: generateId()
            }))
            setDataSource(list)
            queryInjuryBodyName('').then(res => {
                setInjuryBodyOptions(res.data)
            })

        }
        if (enumInfo) {
            setDisabledMap(enumInfo.disabledMap)
        }
    }, [diagnosticInfoModelList, enumInfo]);

    const handleSearchBodyName = async (newValue, record) => {
        if (newValue) {
            const res = await queryInjuryBodyName(newValue);
            console.log('handleSearchBodyName', res.data);
            const updatedData = dataSource.map((item) => ({
                ...item,
                injuryBodyOptions: item.rowKey === record.rowKey ? res.data : []
            }))
            setDataSource(updatedData)
        }
    };

    const handleSearchDiagnoseName = async (newValue, record) => {
        if (newValue) {
            const bodyName = record.bodyName || '';
            const name = typeof newValue === 'string' ? newValue : '';
            const res = await queryDiagnoseName(bodyName, name);
            console.log('handleSearchDiagnoseName', record, res.data);
            const updatedData = dataSource.map((item) => ({
                ...item,
                diagnoseNameOptions: item.rowKey === record.rowKey ? res.data : []
            }))
            setDataSource(updatedData)
        }
    };


    const handleSearchDiagnostic = async (newValue, record) => {
        if (newValue) {
            const { bodyName, diagnoseName } = record;
            const name = typeof newValue === 'string' ? newValue : '';
            const res = await queryDiagnostic(bodyName, diagnoseName, name);
            const updatedData = dataSource.map((item) => ({
                ...item,
                diagnosticOptions: item.rowKey === record.rowKey ? res.data : []
            }))
            setDataSource(updatedData)
        }
    };


    const handleSearchICD10Code = async (newValue, record) => {
        if (newValue) {
            const res = await queryIcd10(newValue, '');
            const updatedData = dataSource.map((item) => ({
                ...item,
                ICD10CodeOptions: item.rowKey === record.rowKey ? res.data : []
            }))
            setDataSource(updatedData)
        }
    };

    const handleSearchICD10Name = async (newValue, record) => {
        if (newValue) {
            const res = await queryIcd10('', newValue);
            const updatedData = dataSource.map((item) => ({
                ...item,
                ICD10NameOptions: item.rowKey === record.rowKey ? res.data : []
            }))
            setDataSource(updatedData)
        }
    };

    const changeValue = (value, key, rowKey) => {
        const updatedData = dataSource.map((item) => {
            if (item.rowKey === rowKey) {
                item[key] = value;
                if (key === 'firstDiagnose') {
                    item[key] = true;
                    // 如果有治疗方式，则更新三期
                    if (item.treatmentWayName) {
                        updateDiagnosticInfo({ bodyName: item.bodyName, diagnoseName: item.diagnoseName, treatmentWayName: item.treatmentWayName });
                    }
                }
                else if (key === 'bodyName') {
                    // 重置联动数据
                    item.diagnoseName = '';
                    item.treatmentWayName = ''
                    item.disabled = ''
                    handleSearchDiagnoseName(true, item)
                }
                else if (key === 'diagnoseName') {
                    // 受伤名称联动受伤部位
                    const filterItem = item?.diagnoseNameOptions?.filter(i => i.diagnoseName === value) || [];
                    item.bodyName = filterItem[0]?.bodyName
                    // 重置联动数据
                    item.treatmentWayName = ''
                    item.disabled = ''
                    handleSearchDiagnostic(true, item)
                }
                else if (key === 'treatmentWayName') {
                    const filterItem = item?.diagnosticOptions?.filter(i => i.treatmentWayName === value) || [];
                    // 是否涉残
                    item.disabled = filterItem[0] ? String(filterItem[0].disabled) : '';
                    // 如果是主要诊断,更新诊断信息
                    if (item.firstDiagnose) {
                        updateDiagnosticInfo({ bodyName: item.bodyName, diagnoseName: item.diagnoseName, treatmentWayName: item.treatmentWayName })
                    }

                }
                else if (key === 'icd10Code') {
                    const filterItem = item?.ICD10CodeOptions?.filter(i => i.icd10Code === value) || []
                    item.icd10Name = filterItem[0] ? filterItem[0].icd10Name : ''
                }
                else if (key === 'icd10Name') {
                    const filterItem = item?.ICD10NameOptions?.filter(i => i.icd10Name === value) || []
                    item.icd10Code = filterItem[0] ? filterItem[0].icd10Code : ''
                }
            } else {
                if (key === 'firstDiagnose') {
                    item.firstDiagnose = false
                }
            }
            return item
        })
        setDataSource(updatedData)
        console.log(`change-${key}`, value, dataSource,)
    }

    const handleAdd = () => {
        const newItem = getDiagnosticInfo();
        const newData = _.cloneDeep(dataSource);
        setDataSource([...newData, newItem]);
    }

    const handleDelete = (index) => {
        const newData = _.cloneDeep(dataSource);
        newData.splice(index, 1)
        setDataSource(newData);
    };

    const columns = [
        {
            title: '主要诊断',
            dataIndex: 'firstDiagnose',
            key: 'firstDiagnose',
            width: 80,
            align: 'center',
            render: (text, record, index) => <div>
                <Radio
                    value={true}
                    checked={!!text}
                    disabled={formDisabled}
                    onChange={(value) => changeValue(value, 'firstDiagnose', record.rowKey)}
                ></Radio>
            </div>,
        },
        {
            title: '受伤部位',
            dataIndex: 'bodyName',
            key: 'bodyName',
            width: 80,
            align: 'center',
            render: (text, record, index) => <div>
                <Select
                    value={text === '' ? undefined : text}
                    key={index}
                    style={{ width: '100%' }}
                    placeholder="请选择"
                    filterOption={false}
                    allowClear
                    showSearch
                    disabled={formDisabled}
                    onSearch={(val) => handleSearchBodyName(val, record)}
                    options={(record?.injuryBodyOptions || injuryBodyOptions || []).map((item) => ({
                        value: item.bodyName,
                        label: item.bodyName,
                    }))}
                    onChange={(value) => changeValue(value, 'bodyName', record.rowKey)}
                >
                </Select>
            </div>,
        },
        {
            title: '受伤名称',
            dataIndex: 'diagnoseName',
            key: 'diagnoseName',
            width: 80,
            align: 'center',
            render: (text, record, index) => <div>
                <Select
                    value={text === '' ? undefined : text}
                    key={index}
                    style={{ width: '100%' }}
                    placeholder="请选择"
                    filterOption={false}
                    allowClear
                    showSearch
                    onSearch={(val) => handleSearchDiagnoseName(val, record)}
                    options={record?.diagnoseNameOptions?.map((item) => ({
                        value: item.diagnoseName,
                        label: item.diagnoseName,
                    }))}
                    onChange={(value) => changeValue(value, 'diagnoseName', record.rowKey)}
                >
                </Select>
            </div>,
        },
        {
            title: '伤情描述',
            dataIndex: 'treatmentWayName',
            key: 'treatmentWayName',
            width: 80,
            align: 'center',
            render: (text, record, index) => <div>
                <Select
                    disabled={!record.diagnoseName || formDisabled}
                    value={text === '' ? undefined : text}
                    key={index}
                    style={{ width: '100%' }}
                    placeholder="请选择"
                    filterOption={false}
                    allowClear
                    showSearch
                    onSearch={(val) => handleSearchDiagnostic(val, record)}
                    options={record?.diagnosticOptions?.map((item) => ({
                        value: item.treatmentWayName,
                        label: item.treatmentWayName,
                    }))}
                    onChange={(value) => changeValue(value, 'treatmentWayName', record.rowKey)}
                >
                </Select>
            </div>,
        },
        {
            title: 'ICD10代码',
            dataIndex: 'icd10Code',
            key: 'icd10Code',
            width: 80,
            align: 'center',
            render: (text, record, index) => <div>
                <Select
                    disabled={formDisabled}
                    value={text === '' ? undefined : text}
                    key={index}
                    style={{ width: '100%' }}
                    placeholder="请选择"
                    filterOption={false}
                    allowClear
                    showSearch
                    onSearch={(val) => handleSearchICD10Code(val, record)}
                    options={record?.ICD10CodeOptions?.map((item) => ({
                        value: item.icd10Code,
                        label: item.icd10Code,
                    }))}
                    onChange={(value) => changeValue(value, 'icd10Code', record.rowKey)}
                >
                </Select>
            </div>,
        },
        {
            title: 'ICD10名称',
            dataIndex: 'icd10Name',
            key: 'icd10Name',
            width: 80,
            align: 'center',
            render: (text, record, index) => <div>
                <Select
                    disabled={formDisabled}
                    value={text === '' ? undefined : text}
                    key={index}
                    style={{ width: '100%' }}
                    placeholder="请选择"
                    filterOption={false}
                    allowClear
                    showSearch
                    onSearch={(val) => handleSearchICD10Name(val, record)}
                    options={record?.ICD10NameOptions?.map((item) => ({
                        value: item.icd10Name,
                        label: item.icd10Name,
                    }))}
                    onChange={(value) => changeValue(value, 'icd10Name', record.rowKey)}
                >
                </Select>
            </div>,
        },
        {
            title: '是否涉残',
            dataIndex: 'disabled',
            key: 'disabled',
            width: 60,
            align: 'center',
            render: (text, record, index) => <div>
                <Select
                    value={text === '' ? undefined : text}
                    key={index}
                    style={{ width: '100%' }}
                    disabled
                    onChange={(value) => changeValue(value, 'disabled', record.rowKey)}
                >
                    {getOptions(disabledMap)}
                </Select>
            </div>,
        },
        {
            title: '',
            key: 'operate',
            width: 40,
            align: 'center',
            fixed: 'right',
            render: (text, record, index) => (dataSource?.length > 1 && !formDisabled) && <div disabled={formDisabled} className='red' onClick={() => handleDelete(index)}>
                删除
            </div>
        },
    ];

    return (
        <>
            <Table
                scroll={{ x: 660 }}
                columns={columns}
                dataSource={dataSource}
                bordered
                rowKey={(record) => record?.rowKey}
                pagination={false}
            ></Table>
        </>

    );
};

export default forwardRef(DiagnosticForm);
