import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Upload,
    Button,
    message
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { search } from '../../../utils'
import { queryMovieList, saveMovie } from '../service'
import ImagePreview from '../../../components/ImagePreview'


const HomePage = ({ history }) => {
    const { caseId } = search(); // URL query参数
    const [previewOpen, setPreviewOpen] = useState(false);
    const [index, setIndex] = useState(0);
    const [fileList, setFileList] = useState([])
    const [fileIds, setFileIds] = useState([]); // 文件ID list
    const [previewList, setPreviewList] = useState([]); // 预览list

    useEffect(() => {
        getMovieList()
    }, []);

    const getMovieList = () => {
        queryMovieList(caseId).then((res) => {
            if (res.success && res.data) {
                setFileList(res.data?.map(item => ({
                    uid: item.id,
                    name: item.fileName,
                    status: 'done',
                    url: item.url
                })))
                setPreviewList(res.data?.map(item => ({
                    src: item.url
                })))
                setFileIds(res.data?.map(item => {
                    return item.id
                }))
            }
            else if (res?.error) {
                message.error(res.error?.message)
            }

        })
    }

    const save = () => {
        const params = {
            id: caseId,
            fileIdList: fileIds,
        }
        console.log('保存影像信息', params);
        saveMovie(params).then((res) => {
            if (res.success) {
                message.success('保存影像信息成功')
                // setTimeout(() => {
                //     history.goBack()
                // }, 1500)
            } else if (res?.error) {
                message.error(res.error?.message);
            }
        });
    }

    const handlePreview = async (file) => {
        let url = file.url || file.preview
        let name = file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        if (file.response) {
            url = file.response?.data[0]?.url;
            name = file.response?.data[0].fileName;
        }
        console.log(file, url);
        const index = previewList?.findIndex(item => item.src === url)
        setIndex(index || 0)
        setPreviewOpen(true);
        // // 新窗口打开图片
        // window.open(url)
    };
    const handleRemove = (file) => {
        let id = file.uid;
        if (file.response) {
            id = file.response?.data[0]?.fileId
        }

        const list = fileIds
        const index = fileIds.findIndex(item => item === id);
        list.splice(index, 1)
        setFileIds(list)
    }
    const handleChange = ({ file, fileList: newFileList }) => {
        setFileList(newFileList)
        if (file.response) {
            setFileIds([...fileIds, file.response?.data[0]?.fileId])
            setPreviewList([...previewList, { src: file.response?.data[0]?.url || '' }])
        }
    };
    return (
        <div style={{ height: '80vh', overflow: 'scroll' }}>
            <Upload
                action='/api/@injury/common/upload'
                listType="picture-card"
                fileList={fileList}
                multiple
                onPreview={handlePreview}
                onRemove={handleRemove}
                onChange={handleChange}
                showUploadList={{ showRemoveIcon: false }}
            >
                <div>
                    <UploadOutlined />
                    <div
                        style={{
                            marginTop: 8,
                        }}
                    >
                        上传
                    </div>
                </div>

            </Upload>

            <ImagePreview
                isShow={previewOpen}
                toggle={() => setPreviewOpen(!previewOpen)}
                index={index}
                images={previewList}
                nextImg={() => setIndex(index + 1)}
                preImg={() => setIndex(index - 1)}
            />
            <div className='footer'>
                <Button className="button" onClick={() => history.goBack()}>
                    返回
                </Button>
                <Button className="button" type='primary' onClick={() => save()}>
                    保存
                </Button>
            </div>
        </div>
    );
};

export default withRouter(HomePage);
