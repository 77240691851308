import React, { useLayoutEffect, useState } from 'react';
import {
    Select
} from 'antd';

import { queryHospital } from '../service'


const getOptions = optionsMap => optionsMap.map(item => (
    <Select.Option
        key={item.code}
        value={item.code}
    >
        {item.name}
    </Select.Option>
));

const HospitalSelect = ({
    hospitalCode,
    hospitalName,
    disabled = false,
    updateHospitalCode = () => { }
}) => {
    const [hospitalOptions, setHospitalOptions] = useState([])
    useLayoutEffect(() => {
        if(hospitalName){
            getHospitalList(hospitalName);
        }
    }, [hospitalName]);

    const getHospitalList = async (name) => {
        const res = await queryHospital(name);
        setHospitalOptions(res.data || []);
    }

    const handleChange = (val) => {
        const filterItem = hospitalOptions.filter(item => item.code === val)
        updateHospitalCode({ code: val, name: filterItem[0]?.name })
    }

    const handleSearch = (newValue) => {
        if (newValue) {
            getHospitalList(newValue);
        } else {
            setHospitalOptions([])
        }
    };

    return (
        <Select
            value={hospitalCode}
            style={{ width: '100%' }}
            placeholder="请选择"
            filterOption={false}
            showSearch
            disabled={disabled}
            onSearch={handleSearch}
            onChange={handleChange}
        >
            {getOptions(hospitalOptions)}
        </Select>
    );
};

export default HospitalSelect;
