import urlUtil from 'url';
import _ from 'lodash';
import querystring from 'querystring';

function addUrlQuery(url, query) {
  const urlObject = urlUtil.parse(url, true);
  let newQuery = query;
  if (typeof newQuery === 'string') {
    if (newQuery.indexOf('?') === 0) {
      newQuery = newQuery.slice(1);
    }
    newQuery = querystring.parse(newQuery);
  }
  urlObject.query = { ...urlObject.query, ...newQuery };
  urlObject.search = null; // 必须将search设置为空，format才会处理query
  return urlUtil.format(urlObject);
}

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = String.prototype.trim.apply(cookies[i]);
      if (cookie.substring(0, name.length + 1) === (`${name}=`)) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function setCookie(name, value, expireDays = 0, cookiePath = '/') {
  const date = new Date();
  date.setTime(date.getTime() + (expireDays * 24 * 60 * 60 * 1000));
  document.cookie = `${name}=${value}; expires=${date.toGMTString()}; path=${cookiePath}`;
}

function clearCookie(name, cookiePath = '/') {
  setCookie(name, '', -1, cookiePath);
}

function isEmpty(value) {
  return [undefined, null, ''].includes(value);
}

function generateId() {
  let d = +new Date();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = ((d + Math.random() * 16) % 16) | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}

const queryParse = (queryString = '') => {
  let query;
  if (queryString.startsWith('?')) {
    query = queryString.substring(1);
  } else {
    query = queryString;
  }
  if (query === '') {
    return {};
  }
  return query.split('&').reduce((prev, cur) => {
    const [k, v = null] = cur.split('=');
    const val = _.isNull(v) ? v : decodeURIComponent(v);
    if (_.isUndefined(prev[k])) {
      prev[k] = val;
    } else {
      prev[k] = _.flatten([prev[k], val]);
    }
    return prev;
  }, {});
};

// 获取 search 参数
const getParams = (key = '') => {
  const params = queryParse(window.location.search);
  if (key === '') {
    return params;
  }
  return params[key];
};

// 将base64转换为文件，dataurl为base64字符串，filename为文件名（必须带后缀名，如.jpg,.png）
const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

// 将图片URL转成base64
function convertUrlToBase64(url) {
  return new Promise((resolve) => {
    let img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = url;
    img.onload = function () {
      let canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      let ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, img.width, img.height);
      let ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase();
      let dataURL = canvas.toDataURL('image/' + ext);
      let base64 = {
        dataURL: dataURL,
        type: 'image/' + ext,
        ext: ext
      };
      resolve(base64)
    }
  })
}


export {
  addUrlQuery,
  getCookie,
  setCookie,
  clearCookie,
  getParams as search,
  isEmpty,
  generateId,
  dataURLtoFile,
  convertUrlToBase64
};

