import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, Space, Row, Col, Button, message } from 'antd';
import html2canvas from 'html2canvas'
import { dataURLtoFile, convertUrlToBase64 } from '../../../utils'
import httpUploadRequest from '../../../utils/cos'
import moment from 'moment';
import { search } from '../../../utils'
import { queryMediationAgreement, createMediationAgreement, cancelMediationAgreement, confirmMediationAgreement } from '../service';

const { Title, Paragraph, Text } = Typography;




const HomePage = ({ history }) => {

    const { caseId } = search(); // URL query参数
    const [id, setId] = useState(null); // 协议书ID
    const [chooseTrigger, setChooseTrigger] = useState(['icon', 'text']);
    const [accidentPlace, setaccidentPlace] = useState('')  // 事故地点
    const [year, setyear] = useState('') // 事故时间-年
    const [month, setmonth] = useState('') // 事故时间-月
    const [day, setday] = useState('') // 事故时间-日
    const [hour, sethour] = useState('') // 事故时间-时
    const [dutyType, setdutyType] = useState('') // 保司责任
    const [partyAAmount, setpartyAAmount] = useState('') // 甲方赔偿金额
    const [partyAAmountWords, setpartyAAmountWords] = useState('') // 甲方赔偿金额大写
    const [partyAMobile, setpartyAMobile] = useState('') // 甲方接受手机号
    const [partyAName, setpartyAName] = useState('') // 甲方姓名
    const [partyCAmount, setpartyCAmount] = useState('') // 丙方赔偿金额
    const [partyCAmountWords, setpartyCAmountWords] = useState('') // 丙方赔偿金额大写
    const [partyBMobile, setpartyBMobile] = useState('') // 乙方接受手机号
    const [partyBName, setpartyBName] = useState('') // 已方姓名
    const [partyCName, setpartyCName] = useState('') // 已方姓名

    const [partyASign, setpartyASign] = useState(false) // 甲方签名状态
    const [partyBSign, setpartyBSign] = useState(false) // 乙方签名状态
    const [partyASignDate, setpartyASignDate] = useState('') // 甲方签名时间
    const [partyBSignDate, setpartyBSignDate] = useState('') // 乙方签名时间

    const [partyCSignDate, setpartyCSignDate] = useState(moment().format('YYYY-MM-DD'))
    const [partyAbase, setpartyAbase] = useState('') // 甲方签名 based64
    const [partyBbase, setpartyBbase] = useState('') // 乙方签名 based64

    const [finishPic, setfinishPic] = useState('') // 最终签名


    useEffect(() => {
        getDetail(caseId);
        formatImage();
    }, []);

    const getDetail = (caseId) => {
        queryMediationAgreement(caseId).then(res => {
            if (res.success && res.data) {
                unFormatDate(res.data?.accidentTime)
                formatImage(res.data)
                setId(res.data?.id)
                setaccidentPlace(res.data?.accidentPlace)
                setdutyType(res.data?.dutyType)
                setpartyAAmount(res.data?.partyAAmount)
                setpartyAAmountWords(res.data?.partyAAmountWords)
                setpartyAMobile(res.data?.partyAMobile)
                setpartyAName(res.data?.partyAName)
                setpartyCAmount(res.data.partyCAmount)
                setpartyCAmountWords(res.data?.partyCAmountWords)
                setpartyBMobile(res.data?.partyBMobile)
                setpartyBName(res.data?.partyBName)
                setpartyCName(res.data?.partyCName)
                setpartyASign(res.data?.partyASign)
                setpartyBSign(res.data?.partyBSign)

                setpartyASignDate(formatSignDate(res.data?.partyASignDate))
                setpartyBSignDate(formatSignDate(res.data?.partyBSignDate))
                setpartyCSignDate(formatSignDate(res.data?.partyCSignDate) || moment().format('YYYY-MM-DD'))

                setfinishPic(res.data?.finishPic)
            } else if (res.error) {
                message.error(res.error?.message)
            }
        })
    }
    const save = () => {
        if (!accidentPlace || !dutyType || !partyAAmount || !partyAMobile || !partyAAmountWords ||
            !partyAName || !partyCAmount || !partyCAmountWords || !partyBMobile || !partyBName || !partyCName ||
            !year || !month || !day || !hour || !partyCSignDate) return message.error('请检查是否填写完整');
        if (partyAMobile?.length !== 11 || partyBMobile?.length !== 11) {
            return message.error('请检查手机号');
        }
        if (year > new Date().getFullYear()) {
            return message.error('请检查事故发生的年份');
        }
        if (month < 1 || month > 12) {
            return message.error('请输入合法的月份');
        }
        if (day < 1 || day > 31) {
            return message.error('请输入合法的日期');
        }
        if (hour < 0 || hour > 24) {
            return message.error('请输入合法的时间');
        }
        if (partyCAmount < 0 || partyAAmount < 0) {
            return message.error('请输入大于0的金额');
        }
        const accidentTime = formatDate(year, month, day, hour);
        if (accidentTime < 0) {
            return message.error('请检查事故发生的时间');
        }
        let params = { caseId, id, accidentTime, accidentPlace, dutyType, partyAAmount, partyAAmountWords, partyAMobile, partyAName, partyCAmount, partyCAmountWords, partyBMobile, partyBName, partyCName, partyCSignDate: +moment(partyCSignDate) }
        console.log('保存协议书参数', params);
        createMediationAgreement(params).then((res) => {
            if (res.success && res.data) {
                message.success('保存成功')
                setId(res.data)
            } else if (res.error) {
                message.error(res.error?.message)
            }
        });
    }


    const sendCode = async (partyType) => {
        if (!id) return message.error('请保存后再发送')
        cancelMediationAgreement({ id, partyType }).then(res => {
            if (res.success) {
                message.success('发送成功')
            } else if (res.error) {
                message.error(res.error?.message)
            }
        })
    }

    const submit = async () => {
        const picUrl = await getCanvas2ImageUrl()
        if (!partyBSign || !partyBSign) message.error('请保111发送')
        confirmMediationAgreement({ id, picUrl }).then(res => {
            if (res.success) {
                message.success('协议书提交成功')
                setTimeout(() => {
                    getDetail(caseId);
                }, 1500)
            } else if (res.error) {
                message.error(res.error?.message)
            }
        })

    }

    const formatDate = (year, month, day, hour) => {
        const time = `${year} ${month} ${day} ${hour}`
        return +moment(time, "YYYY MM DD h")
    }

    const formatSignDate = (date) => {
        if (date) {
            return moment(date).format('YYYY-MM-DD')
        }
    }

    const unFormatDate = (date) => {
        if (!date) return
        const time = new Date(date)
        setyear(time.getFullYear())
        setmonth(time.getMonth() + 1)
        setday(time.getDate())
        sethour(time.getHours())
    }

    const formatImage = (res) => {
        if (!res) return
        convertUrlToBase64(res.partyASignPic).then(res => {
            setpartyAbase(res.dataURL)
        })
        convertUrlToBase64(res.partyBSignPic).then(res => {
            setpartyBbase(res.dataURL)
        })
    }

    const getCanvas2ImageUrl = async () => {
        // 先滚动到顶部，否则生成图片存在空白
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;  // 兼容safari老版本
        try {
            const canvas = await html2canvas(document.querySelector('.agreement'))
            let img = canvas.toDataURL("image/png")
            const file = dataURLtoFile(img, 'sign.png')
            const res = await httpUploadRequest({ file })
            const imageUrl = `https://${res.Location}`;
            console.log('签名合照', imageUrl);
            return imageUrl
        } catch (e) {
            message.error(e);
        }
    }



    return (
        <>
            {
                finishPic ? <>
                    <div className='finish-pic'>
                        <img width={'80%'} src={finishPic} />
                    </div>
                    <div className='footer'>
                        <Button className="button" onClick={() => history.goBack()}>
                            返回
                        </Button>
                    </div>
                </>
                    : <>
                        <div className='agreement'>
                            <Title level={2} >
                                <div style={{ textAlign: 'center' }}>
                                    人伤调解处理协议书
                                </div>
                            </Title>
                            <div className='header'>
                                <Space direction='vertical' size={16}>
                                    <div className='name'>
                                        甲方（被保险人）:<Text
                                            editable={{
                                                tooltip: '点击输入',
                                                onChange: setpartyAName,
                                                triggerType: chooseTrigger,
                                            }} >
                                            {partyAName}
                                        </Text>
                                        甲方手机号: <Text editable={{
                                            tooltip: '点击输入手机号',
                                            onChange: setpartyAMobile,
                                            triggerType: chooseTrigger,

                                        }}>
                                            {partyAMobile}
                                        </Text>
                                    </div>
                                    <div className='name'>
                                        乙方（受害人）: <Text editable={{
                                            tooltip: '点击输入',
                                            onChange: setpartyBName,
                                            triggerType: chooseTrigger,
                                        }}>
                                            {partyBName}
                                        </Text>
                                        乙方手机号:<Text editable={{
                                            tooltip: '点击输入手机号',
                                            onChange: setpartyBMobile,
                                            triggerType: chooseTrigger,
                                        }}>
                                            {partyBMobile}
                                        </Text>
                                    </div>
                                    <div className='name'>
                                        丙方（保险公司）：<Text editable={{
                                            tooltip: '点击输入',
                                            onChange: setpartyCName,
                                            triggerType: chooseTrigger,
                                        }} > {partyCName}</Text>
                                    </div>
                                </Space>
                            </div>

                            <div className='content'>
                                <Space direction='vertical' size={16}>
                                    <Paragraph>
                                        甲方车于<Text
                                            editable={{
                                                tooltip: '点击输入年',
                                                onChange: setyear,
                                                triggerType: chooseTrigger,
                                            }}
                                        >
                                            {year}
                                        </ Text>年
                                        <Text
                                            editable={{
                                                tooltip: '点击输入月',
                                                onChange: setmonth,
                                                triggerType: chooseTrigger,
                                            }}
                                            disabled={!year}
                                        >
                                            {month}
                                        </ Text>月
                                        <Text
                                            editable={{
                                                tooltip: '点击输入日',
                                                onChange: setday,
                                                triggerType: chooseTrigger,
                                            }}
                                            disabled={!month}
                                        >
                                            {day}
                                        </ Text>日
                                        <Text
                                            editable={{
                                                tooltip: '点击输入时',
                                                onChange: sethour,
                                                triggerType: chooseTrigger,
                                            }}
                                            disabled={!day}
                                        >
                                            {hour}
                                        </ Text>时，在
                                        <Text
                                            editable={{
                                                tooltip: '点击输入',
                                                onChange: setaccidentPlace,
                                                triggerType: chooseTrigger,
                                            }}
                                        >
                                            {accidentPlace}
                                        </ Text>地点发生的交通事故中对乙方造成了一定的损害，经过交警判定甲方为
                                        <Text
                                            editable={{
                                                tooltip: '点击输入',
                                                onChange: setdutyType,
                                                triggerType: chooseTrigger,
                                            }}
                                        >
                                            {dutyType}
                                        </ Text>责任，经事故当事人甲方、乙方、丙方共同协商达成一致意见：丙方就此次事故一次性赔乙方：
                                        <Text
                                            editable={{
                                                tooltip: '点击输入',
                                                onChange: setpartyCAmount,
                                                triggerType: chooseTrigger,
                                            }}
                                        >
                                            {partyCAmount}
                                        </ Text>元。大写：
                                        <Text
                                            editable={{
                                                tooltip: '点击输入',
                                                onChange: setpartyCAmountWords,
                                                triggerType: chooseTrigger,
                                            }}
                                        >
                                            {partyCAmountWords}
                                        </ Text>。甲方就此次事故一次性赔乙 方：
                                        <Text
                                            editable={{
                                                tooltip: '点击输入',
                                                onChange: setpartyAAmount,
                                                triggerType: chooseTrigger,
                                            }}
                                        >
                                            {partyAAmount}
                                        </ Text>元，大写：
                                        <Text
                                            editable={{
                                                tooltip: '点击输入',
                                                onChange: setpartyAAmountWords,
                                                triggerType: chooseTrigger,
                                            }}
                                        >
                                            {partyAAmountWords}
                                        </ Text>。
                                    </Paragraph>
                                    <Paragraph>
                                        甲方赔付乙方的金额是此次事故中保险责任范围内人伤损失。本协议签定后，关于本次事故对乙方所造成的人身损害，丙、甲双方已经全部赔偿完毕，本次事故一次性了结，甲、乙方双方保证无论以后发生任何情况，不再就上述交通事故向丙方提出赔偿请求，甲、乙自愿放弃就本次事故享有的仲裁、诉讼等权利。
                                    </Paragraph>
                                    <Paragraph>
                                        甲方、乙方、丙方均已完全理解本协议的法律含义，各方在完全自愿的情况下签定本协议。本协议经各方同意，签字或摁手印之日起生效，具有法律效力。
                                    </Paragraph>
                                </Space>
                            </div>

                            <Row gutter={24}>
                                <Col span={8}>
                                    <div className='sign'>甲方：
                                        <div>
                                            <img src={partyAbase} />
                                        </div>
                                    </div>
                                </Col>

                                <Col span={8}>
                                    <div className='sign'>乙方：
                                        <div>
                                            <img src={partyBbase} />
                                        </div>
                                    </div>
                                </Col>

                                <Col span={8}>
                                    <div className='sign'>丙方：
                                        <div>
                                            {partyCName}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <div>签名时间：{partyASignDate}</div>
                                </Col>

                                <Col span={8}>
                                    <div>签名时间：{partyBSignDate}</div>
                                </Col>

                                <Col span={8}>
                                    <div>签名时间：{partyCSignDate}</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='footer'>
                            <Button className="button" onClick={() => history.goBack()}>
                                返回
                            </Button>
                            <Button
                                className="button"
                                type="primary"
                                disabled={partyASign || partyBSign}
                                onClick={() => save()}
                            >
                                保存
                            </Button>
                            <Button
                                className="button"
                                type="primary"
                                disabled={!id}
                                onClick={() => sendCode(1)}
                            >
                                甲方发送
                            </Button>
                            <Button
                                className="button"
                                type="primary"
                                disabled={!id}
                                onClick={() => sendCode(2)}
                            >
                                乙方发送
                            </Button>
                            <Button
                                className="button"
                                type="primary"
                                disabled={!partyASign || !partyBSign}
                                onClick={() => submit()}
                            >
                                提交
                            </Button>
                        </div>
                    </>
            }

        </>

    );
};

export default withRouter(HomePage);
