import { GET, POST } from '../../utils/request';

/**
 * 活动列表查询
 * @param {string} activityName 
 * @param {number} cycleType
 * @param {number} id
 * @param {number} status
 * @param {number} limit
 * @param {number} offset
 */
export async function getUserList(body) {
  return await POST(
    '/api/@injury/sysAdmin/list',
    body
  );
}


/**
 * 创建用户
 */
export async function createUser(body) {
  return await POST(
    '/api/@injury/sysAdmin/create',
    body
  );
}

/**
 * 禁用用户
 */
export async function disableUser(userId) {
  return await GET(
    `/api/@injury/sysAdmin/disable?userId=${userId}`
  );
}



/**
 * 操作记录
 * @param {number} caseId
 */
 export async function queryOperateRecord(id) {
  return await GET(`/api/@injury/common/queryOperateRecord?id=${id}&modelType=1`);
}






