import { GET, POST } from '../../utils/request';


/**
 * 人伤列表查询
* @param {string} claimItemCode 赔付项目编码
* @param {string} claimTreatmentTypeCode 案件就诊类型编码
* @param {date-time} createDateEnd 创建时间结束
* @param {date-time} createDateStart 创建时间开始
* @param {string} customerName 客户名称
* @param {date-time} damageDate 出险日期
* @param {string} dealPersonMobile 任务处理人手机号
* @param {string} dealPersonName 任务处理人名称
* @param {string} filingNo 立案号
* @param {int64} id id
* @param {int32} limit
* @param {string} lossTypeCode 损失类型编码
* @param {string} offset
* @param {string} reportNo 报案号
* @param {int32} status 任务状态
 */
export async function getInjuryList(body) {
    return await POST(
        '/api/@injury/personInjury/list',
        body
    );
}

/**
 * 操作记录
 * @param {number} caseId
 */
export async function queryOperateRecord(id) {
    return await GET(`/api/@injury/common/queryOperateRecord?id=${id}&modelType=2`);
}

/**
 * 删除案件
 * @param {number} id
 */
export async function deleteInjury(body) {
    return await POST(
        '/api/@injury/personInjury/delete',
        body
    );
}

/**
 * 重新打开案件
 * @param {number} id
 */
export async function reOpenInjury(body) {
    return await POST(
        '/api/@injury/personInjury/reOpen',
        body
    );
}

/**
 * 导出案件列表
 * @param {number} id
 */
export async function exportInjuryList(body) {
    return await POST(
        '/api/@injury/personInjury/export',
        body
    );
}

/**
 * 导入案件
 * @param {number} id
 */
export async function uploadInjury(body) {
    return await POST(
        '/api/@injury/personInjury/upload',
        body
    );
}

/**
 * 查询人伤详情信息
 * @param {number} id
 */
export async function queryPersonInjuryDetail(id) {
    return await GET(`/api/@injury/personInjury/queryPersonInjuryDetail?id=${id}`);
}

/**
 * 查询影像信息
 * @param {number} id
 */
export async function queryMovieList(id) {
    return await GET(`/api/@injury/personInjury/queryMovieList?id=${id}`);
}

/**
 * 保存影像信息
 * @param {number} id
 */
export async function saveMovie(body) {
    return await POST(
        '/api/@injury/personInjury/saveMovie',
        body
    );
}

/**
 * 查询用户信息
 * @param {string} name 姓名
 */
export async function queryByName(name) {
    return await GET(
        `/api/@injury/sysAdmin/queryByName?name=${name}`
    );
}


/**
 * 案件转交
 * @param {number} id
 * @param {string} name 姓名
 * @param {string} mobile 手机号
 */
export async function transferInjury(body) {
    return await POST(
        '/api/@injury/personInjury/transfer',
        body
    );
}

/**
* 人伤认领
* @param {int64} id id
*/
export async function receive(body) {
    return await POST(
        '/api/@injury/personInjury/receive',
        body
    );
}

/**
* 电子协议书/资料收集短信发送
* @param {int64} id id
* @param {operateType} 
*/
export async function sendMessage(body) {
    return await POST(
        '/api/@injury/personInjury/sendMessage',
        body
    );
}

/**
* 暂存人伤信息
*/
export async function saveInjury(body) {
    return await POST(
        '/api/@injury/personInjury/save',
        body
    );
}

/**
* 提交人伤信息
*/
export async function submitInjury(body) {
    return await POST(
        '/api/@injury/personInjury/submit',
        body
    );
}


/**
* 计算定损单
*/
export async function calculateOrder(body) {
    return await POST(
        '/api/@injury/personInjury/calculate',
        body
    );
}


/**
 * 查询枚举
 */
export async function queryInjuryEnums() {
    return await GET(`/api/@injury/common/queryEnums`);
}

/**
 * 查询区域代码
 */
export async function queryArea(name = '') {
    return await GET(`/api/@injury/common/queryArea?name=${name}`);
}

/**
 * 查询区县代码
 */
export async function queryCountry(cityCode = '') {
    return await GET(`/api/@injury/common/queryCounty?cityCode=${cityCode}`);
}

/**
 * 查询医院信息
 * @param {string} name 
 */
export async function queryHospital(name) {
    return await GET(`/api/@injury/common/queryHospital?name=${name}`);
}

/**
 * 查询受伤部位
 * @param {string} name 
 */
export async function queryInjuryBodyName(bodyName) {
    return await GET(`/api/@injury/common/queryInjuryBodyName?bodyName=${bodyName}`);
}


/**
 * 查询受伤名称
 * @param {string} name 
 */
export async function queryDiagnoseName(bodyName, diagnoseName) {
    return await GET(`/api/@injury/common/queryDiagnoseName?bodyName=${bodyName}&diagnoseName=${diagnoseName}`);
}


/**
 * 查询伤情描述和诊断信息
 * @param {string} name 
 */
export async function queryDiagnostic(bodyName, diagnoseName, treatmentWayName) {
    return await GET(`/api/@injury/common/queryDiagnostic?bodyName=${bodyName}&diagnoseName=${diagnoseName}&treatmentWayName=${treatmentWayName}`);
}

/**
 * 查询 ICD10 信息
 * @param {string} code
 * @param {string} name 
 */
export async function queryIcd10(code, name) {
    return await GET(`/api/@injury/common/queryIcd10?code=${code}&name=${name}`);
}

/**
 * 获取抚养年限
 * @param {string} age 
 */
export async function queryDependentYears(age) {
    return await GET(`/api/@injury/common/dependentYears?age=${age}`);
}

/**
 * 查询伤残部位
 * @param {string} name 
 */
export async function queryDisabledBody(name) {
    return await GET(`/api/@injury/common/queryDisabledBody?name=${name}`);
}

/**
 * 查询伤残描述和伤残信息
 * @param {string} name 
 */
export async function queryDisabled(disabledBodyName, disLevelDescribe) {
    return await GET(`/api/@injury/common/queryDisabled?disabledBodyName=${disabledBodyName}&disLevelDescribe=${disLevelDescribe}`);
}

/**
 * 查询鉴定机构信息
 * @param {string} name 
 */
export async function queryAppraisalOrg(name) {
    return await GET(`/api/@injury/common/queryAppraisalOrg?name=${name}`);
}

/**
 * 查询三期天数
 */
export async function queryThreeLimit({ bodyName, diagnoseName, treatmentWayName }) {
    return await GET(`/api/@injury/common/queryThreeLimit?bodyName=${bodyName}&diagnoseName=${diagnoseName}&treatmentWayName=${treatmentWayName}`);
}

/**
 * 查询赔偿年度
 */
export async function queryYear(provinceCode) {
    return await GET(`/api/@injury/common/queryYear?provinceCode=${provinceCode}`);
}

/**
 * 查询赔偿标准
 */
export async function queryCompensationStandard(provinceCode, cityCode, year) {
    return await GET(`/api/@injury/common/queryCompensationStandard?provinceCode=${provinceCode}&cityCode=${cityCode}&year=${year}`);
}

/**
* 计算伤残叠加系数
*/
export async function computeDisabledComposition(body) {
    return await POST(
        '/api/@injury/common/computeDisabledComposition',
        body
    );
}


/**
* 查看调解同意书
*/
export async function queryMediationAgreement(caseId) {
    return await GET(`/api/@injury/mediationAgreement/detail?caseId=${caseId}`);
}


/**
* 创建/更改调解同意书
*/
export async function createMediationAgreement(body) {
    return await POST(
        '/api/@injury/mediationAgreement/create',
        body
    );
}

/**
* 作废签名重新发送
*/
export async function cancelMediationAgreement(body) {
    return await POST(
        '/api/@injury/mediationAgreement/cancel',
        body
    );
}

/**
* 确认提交授权协议书
*/
export async function confirmMediationAgreement(body) {
    return await POST(
        '/api/@injury/mediationAgreement/confirm',
        body
    );
}


